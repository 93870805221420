/* eslint-disable no-underscore-dangle */
import React, { useEffect, useReducer, useState } from 'react';
import 'styles/User/TransponderListUser.css';
import { Link } from 'react-router-dom';
import {
  Button, Col, Dropdown, Form, Row, Stack, Table,
} from 'react-bootstrap';
import DashboardLayoutUser from 'components/layout/DashboardLayoutUser';

import axios from 'axios';
import { getCookie, setCookie } from 'utils/SetCookies';
import LoadingPageUser from 'components/LoadingPageUser';
import { INITIAL_STATE, TransponderListUserReducer } from 'reducer/User/TransponderListUserReducer';
import PaginationControl from 'components/PaginationControl';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import RegisterTransponderBtn from 'components/buttonModal/RegisterTransponderBtn';

function TransponderListUser() {
  // const navigate = useNavigate();
  const [state, dispatch] = useReducer(TransponderListUserReducer, INITIAL_STATE);
  const [pagination, setPagination] = useState({
    current_page: 1, entries: 10, pagination_items: [], multiple: 0,
  });

  const auth = { token: getCookie('token') };

  function checkStatus(status) {
    if (status.toLowerCase() === 'active') {
      return (<div className="status-active text-center px-3 py-2 rounded">Active</div>);
    }
    return (<div className="status-inactive text-center px-3 py-2 rounded">Inactive</div>);
  }

  function checkLicense(license) {
    if (license.toLowerCase() === 'accepted') {
      return (<div className="status-accepted text-center px-3 py-2 rounded">Accepted</div>);
    } if (license.toLowerCase() === 'revision') {
      return (<div className="status-revision text-center px-3 py-2 rounded">Revision</div>);
    } if (license.toLowerCase() === 'rejected') {
      return (<div className="status-rejected text-center px-3 py-2 rounded">Rejected</div>);
    }
    return (<p className="text-center">-</p>);
    // return (<div className="status-notFilled text-center px-3 py-2 rounded">Not Filled Yet</div>);
  }

  const getDataPagination = async () => {
    axios({
      method: 'get',
      url: `transponder/pagination/${pagination.current_page}/${pagination.entries}`, // pagination/current_page/many
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        if (res.data.status !== 204) {
          const dataBaru = res.data.data.results;

          dispatch({
            type: 'UPDATE_DATA',
            payload: {
              name: 'dataUser',
              data: dataBaru,
            },
          });
        }
      }
    });
  };

  const fetchGetTransponderList = async () => {
    axios({
      method: 'get',
      url: `transponder/pagination/${pagination.current_page}/${pagination.entries}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        if (res.data.status !== 204) {
          console.log(res);
          const dataBaru = res.data.data.results;

          for (let number = 1; number <= res.data.data.total_pages; number += 1) {
            pagination.pagination_items.push(number);
          }

          dispatch({
            type: 'SET_DATA',
            payload: {
              name: 'dataUser',
              data: dataBaru,
            },
          });
        }
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      }
    });
  };

  console.log(state.dataUser);

  useEffect(() => {
    getDataPagination();
  }, [pagination.current_page, pagination.entries]);

  useEffect(() => {
    if (auth.token) {
      dispatch({ type: 'SET_IS_LOADING', payload: true });
      setCookie('navName', 'Transponder List');
      fetchGetTransponderList();
    }
  }, []);

  console.log(state.dataUser);

  if (state.isLoading) {
    return <LoadingPageUser />;
  }
  return (
    <DashboardLayoutUser>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/user">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/user/transponder-list">Transponder</a></li>
          <li className="breadcrumb-item active" aria-current="page">Transponder List</li>
        </ol>
      </nav>
      <Row className="mb-3">
        <Col className="col-auto me-auto"><h1 className="fs-3 fw-bold m-0">Transponder List</h1></Col>
        <Col className="col-auto">
          <RegisterTransponderBtn fetchGetTransponderList={fetchGetTransponderList} />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-auto me-auto">
          <p className="fs-5">
            Showing
            {' '}
            {pagination.multiple + 1}
            {' '}
            to
            {' '}
            {(pagination.multiple + parseInt(pagination.entries, 10) < pagination.pagination_items.length) ? (pagination.multiple + parseInt(pagination.entries, 10)) : pagination.pagination_items.length}
            {' '}
            of
            {' '}
            {pagination.entries}
            {' '}
            entries
          </p>
        </Col>
        <Col className="col-auto">
          <Stack direction="horizontal" gap={3}>
            <Dropdown onSelect={(e) => setPagination({
              ...pagination, current_page: 1, entries: e, multiple: 0,
            })}
            >
              <DropdownToggle variant="light" id="entries" className="fw-semibold d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>
                <p className="m-0 p-0">
                  {pagination.entries}
                  {' '}
                  Entries
                </p>
              </DropdownToggle>

              <DropdownMenu>
                <Dropdown.Item eventKey="10">10</Dropdown.Item>
                <Dropdown.Item eventKey="20">20</Dropdown.Item>
                <Dropdown.Item eventKey="30">30</Dropdown.Item>
                <Dropdown.Item eventKey="40">40</Dropdown.Item>
                <Dropdown.Item eventKey="50">50</Dropdown.Item>
              </DropdownMenu>
            </Dropdown>

            <Button type="button" className="btn btn-light fw-semibold d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-list-ul me-2" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
              <p className="m-0 p-0">Column</p>
            </Button>
            <Button type="button" className="btn btn-light fw-semibold d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-download me-2" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
              </svg>
              <p className="m-0 p-0">Export</p>
            </Button>
          </Stack>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-3 ms-auto">
          <Form.Control
            type="text"
            id="searchBar"
            placeholder="Search..."
          />
        </Col>
      </Row>

      <Table hover className="align-middle table-borderless">
        <thead>
          <tr className="text-center">
            <th className="leftCornerTableRadius">No</th>
            <th>Transponder IMEI</th>
            <th>Transponder Name</th>
            <th>Serial Number</th>
            <th>Description</th>
            <th>Status</th>
            <th>License Validation</th>
            <th className="rightCornerTableRadius">Action</th>
          </tr>
        </thead>
        <tbody>
          {state.dataUser.length !== 0
            ? state?.dataUser?.map((item, index) => (
              <tr key={item._id}>
                <td className="text-center">{pagination.pagination_items[index + (pagination.multiple)]}</td>
                <td>
                  <Link
                    className="transponderIMEI fw-semibold"
                    to={`/user/transponder-list/detail-drone/${item.imei}`}
                  >
                    {item.imei}
                  </Link>
                </td>
                <td>{item.name}</td>
                <td>{item.serial_number}</td>
                <td>{item.notes}</td>
                <td>
                  {checkStatus(item.status)}
                </td>
                <td>
                  {checkLicense(item.licence_validation)}
                </td>
                <td>
                  <div className="d-flex justify-content-center align-items-center h-100 m-0">
                    <Button
                      as={Link}
                      to={`/user/transponder-list/edit-detail/${item.imei}/${item._id}`}
                      type="button"
                      className="btn fw-bold d-flex align-items-center p-2"
                      variant="outline-primary"
                      // onClick={() => navigate()}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                      </svg>
                    </Button>
                  </div>
                </td>
              </tr>
            ))
            : (
              <tr>
                <td colSpan="6" className="text-center">No data found</td>
              </tr>
            )}
        </tbody>
      </Table>

      <Row className="align-items-center justify-content-center">
        <Col className="col-auto">
          <PaginationControl itemsPerPage={pagination.entries} items={pagination.pagination_items} pagination={pagination} setPagination={setPagination} />
        </Col>
      </Row>

    </DashboardLayoutUser>
  );
}

export default TransponderListUser;
