import { Formik } from 'formik';
import React, { useState } from 'react';
import {
  Button, Form, InputGroup, Modal, Stack,
} from 'react-bootstrap';
import * as Yup from 'yup';

function AddAdminBtn() {
  const [modalShow, setModalShow] = useState(false);

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required'),
    password: Yup.string().required('Password is required'),
    email: Yup.string().required('Email is required'),
  });

  const handleFormSubmit = () => {
    setModalShow(false);
  };

  return (
    <>
      <Button type="button" className="btn btn-primary fw-bold d-flex align-items-center" onClick={() => setModalShow(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
        </svg>
        <p className="m-0 p-0">Add Admin</p>
      </Button>

      <Modal centered className="modal-lg" show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg closeButtonModal position-absolute me-3 end-0" onClick={() => setModalShow(false)} viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
          <Modal.Title className="fs-5 fw-bold w-100 text-center">Add Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-4">

          <Formik
            validationSchema={schema}
            onSubmit={(values) => handleFormSubmit(values)}
            initialValues={{
              name: '',
              email: '',
              password: '',
              phone: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit} className="text-start">
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label className="fw-semibold">
                    Name
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.name && errors.name ? <p className="error-text text-danger">{errors.name}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label className="fw-semibold">
                    Phone
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.phone && errors.phone ? <p className="error-text text-danger">{errors.phone}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label className="fw-semibold">
                    Password
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.password && errors.password ? <p className="error-text text-danger">{errors.password}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="email">
                  <Form.Label className="fw-semibold">
                    Email
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.email && errors.email ? <p className="error-text text-danger">{errors.email}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Button className="w-100" type="submit" variant="primary">
                  Add User
                </Button>
              </Form>

            )}
          </Formik>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddAdminBtn;
