/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
import React, { useEffect, useReducer, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Row, Spinner, Tab, Tabs,
} from 'react-bootstrap';
import DashboardLayoutUser from 'components/layout/DashboardLayoutUser';
import { useParams, Link as ReachLink, useNavigate } from 'react-router-dom';
import { getCookie, setCookie } from 'utils/SetCookies';
import 'styles/User/DetailMonitoringUser.css';
import { INITIAL_STATE, TransponderListUserReducer } from 'reducer/User/TransponderListUserReducer';
import axios from 'axios';
import AlertReact from 'components/alert/AlertReact';
import { Formik } from 'formik';
import * as Yup from 'yup';
import UploadLicenseFile from './UploadLicenseFile';
import DroneDetailForm from './DroneDetailForm';

function EditInputDetailUser() {
  const navigate = useNavigate();
  const { imei, id } = useParams();

  const auth = { token: getCookie('token') };

  const [state, dispatch] = useReducer(TransponderListUserReducer, INITIAL_STATE);
  const [showAlert, setShowAlert] = useState(false);
  const [alertDetail, setAlertDetail] = useState({
    message: '', variant: '',
  });

  const schema = Yup.object().shape({
    droneName: Yup.string().required('Drone Name is required'),
    serialNumber: Yup.string().required('Serial Number is required'),
    weightWithoutPayload: Yup.string().required('weight Without Payload is required'),
    cruiseSpeed: Yup.string().required('Cruise Speed is required'),
    maxRateOfClimb: Yup.string().required('Maximum Rate Of Climb is required'),
    maxFlightRange: Yup.string().required('Maximum Flight Range is required'),
    maxSpeed: Yup.string().required('Maximum Speed is required'),
    maxCruiseHeight: Yup.string().required('Maximum Cruise Height is required'),
    operationalPayloadWeight: Yup.string().required('Operational Payload Weight is required'),

    prosedurKedaruratan: Yup.mixed().required('Prosedur Kedaruratan is required').test('fileSize', 'File too large, max 5 Mb', (value) => value && value.size <= (5000000)),
    fotoDrone: Yup.mixed().required('Foto Drone is required').test('fileSize', 'File too large, max 5 Mb', (value) => value && value.size <= (5000000)),
    dokumenAsuransi: Yup.mixed().required('Dokumen Asuransi is required').test('fileSize', 'File too large, max 5 Mb', (value) => value && value.size <= (5000000)),
    suratIzinDNP: Yup.mixed().required('Surat Izin DNP is required').test('fileSize', 'File too large, max 5 Mb', (value) => value && value.size <= (5000000)),
    suratPermohonan: Yup.mixed().required('Surat Permohonan dan Assesment Ruang Udara is required').test('fileSize', 'File too large, max 5 Mb', (value) => value && value.size <= (5000000)),
    suratIzinMiliter: Yup.mixed().required('Surat Izin Militer is required').test('fileSize', 'File too large, max 5 Mb', (value) => value && value.size <= (5000000)),
    daftarPeralatan: Yup.mixed().required('Daftar Peralatan is required').test('fileSize', 'File too large, max 5 Mb', (value) => value && value.size <= (5000000)),
    suratIzinOtoritas: Yup.mixed().required('Surat Izin Otoritas is required').test('fileSize', 'File too large, max 5 Mb', (value) => value && value.size <= (5000000)),
  });

  const fetchAddDetailInput = async (values) => {
    dispatch({ type: 'SET_IS_LOADING_SUBMIT', payload: true });
    const paramsData = {
      name: values.droneName,
      serial_number: values.serialNumber,
      configuration: values.volumetricPayload,
      max_take_weight: values.maxTakeOffWeight,
      weight_without_payload: values.weightWithoutPayload,
      max_flight_range: values.maxFlightRange,
      cruise_speed: values.cruiseSpeed,
      maximum_speed: values.maxSpeed,
      max_cruise_height: values.maxCruiseHeight,
      fuselage_material: values.fuselageMaterial,
      operational_payload_weight: values.operationalPayloadWeight,
      operational_payload_height: values.maxRateOfClimb,
      proximity_sensors: values.proximitySensors,
      wing_material: values.wingMaterial,
      precision_landing_mechanism: values.precisionLoading,
      file_save_system: values.fileSaveSystem,
      operation_system: values.operationSystem,
      control_system: values.controlSystem,
      communication_system: values.communicationSystem,
      description: values.description,
    };

    const form = new FormData();
    form.append('transponder_id', id);
    form.append('params', JSON.stringify(paramsData));
    form.append('drone_image', values.fotoDrone);
    form.append('prosedur_kedaruratan', values.prosedurKedaruratan);
    form.append('dokumen_asuransi', values.dokumenAsuransi);
    form.append('surat_assesment', values.suratPermohonan);
    form.append('daftar_peralatan', values.daftarPeralatan);
    form.append('surat_ijin', values.suratIzinDNP);
    form.append('surat_ijin_militer', values.suratIzinMiliter);
    form.append('surat_ijin_obv', values.suratIzinOtoritas);

    await axios({
      method: 'post',
      url: 'drones',
      headers: {
        authorization: `Bearer ${auth.token}`,
        'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
      },
      data: form,
    }).then((res) => {
      if (res) {
        dispatch({ type: 'SET_IS_LOADING_SUBMIT', payload: false });
        console.log(JSON.stringify(res.data));
        const messageAlert = res?.data?.message;
        setAlertDetail({ message: messageAlert, variant: 'success' });
        setShowAlert(true);
        const timeId = setTimeout(() => {
          setShowAlert(false);
          // navigate(-1);
          // navigate(`/user/transponder-list/detail-drone/${imei}`);
        }, 1500);

        return () => {
          clearTimeout(timeId);
        };
      }
      return null;
    }).catch((res) => {
      dispatch({ type: 'SET_IS_LOADING_SUBMIT', payload: false });
      const messageAlert = res?.data?.message;
      setAlertDetail({ message: messageAlert, variant: 'danger' });
      setShowAlert(true);
      const timeId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    });
  };

  const handleFormSubmit = (values) => {
    console.log(values);
    fetchAddDetailInput(values);
  };

  const fetchGetDetailDrone = () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    axios({
      method: 'get',
      url: `transponder/details/${imei}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        console.log(res);
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'detailDrone',
            data: res.data.data,
            isLoading: false,
          },
        });
      }
    });
  };

  console.log(state.detailDrone);

  useEffect(() => {
    if (auth.token) {
      setCookie('navName', 'Transponder Detail');
      fetchGetDetailDrone();
    }
  }, []);

  return (
    <DashboardLayoutUser>
      <Row className="position-relative">
        <div className="position-fixed end-0 me-4" style={{ width: 'inherit' }}>
          <AlertReact message={alertDetail.message} showAlert={showAlert} setShowAlert={setShowAlert} variant={alertDetail.variant} />
        </div>
      </Row>

      <Formik
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values) => handleFormSubmit(values)}
        initialValues={{
          droneName: state?.detailDrone?.drone?.name,
          serialNumber: state?.detailDrone?.drone?.serial_number,
          weightWithoutPayload: state?.detailDrone?.drone?.weight_without_payload,
          cruiseSpeed: state?.detailDrone?.drone?.cruise_speed,
          maxRateOfClimb: state?.detailDrone?.drone?.operational_payload_height,
          volumetricPayload: state?.detailDrone?.drone?.configuration,
          wingMaterial: state?.detailDrone?.drone?.wing_material,
          fuselageMaterial: state?.detailDrone?.drone?.fuselage_material,
          fileSaveSystem: state?.detailDrone?.drone?.file_save_system,
          controlSystem: state?.detailDrone?.drone?.control_system,
          maxTakeOffWeight: state?.detailDrone?.drone?.max_take_weight,
          maxFlightRange: state?.detailDrone?.drone?.max_flight_range,
          maxSpeed: state?.detailDrone?.drone?.maximum_speed,
          maxCruiseHeight: state?.detailDrone?.drone?.max_cruise_height,
          operationalPayloadWeight: state?.detailDrone?.drone?.operational_payload_weight,
          proximitySensors: state?.detailDrone?.drone?.proximity_sensors,
          precisionLoading: state?.detailDrone?.drone?.precision_landing_mechanism,
          operationSystem: state?.detailDrone?.drone?.operation_system,
          communicationSystem: state?.detailDrone?.drone?.communication_system,
          description: state?.detailDrone?.drone?.description,

          prosedurKedaruratan: state?.detailDrone?.drone?.prosedur_kedaruratan,
          fotoDrone: state?.detailDrone?.drone?.drone_image,
          dokumenAsuransi: state?.detailDrone?.drone?.dokumen_asuransi,
          suratIzinDNP: state?.detailDrone?.drone?.surat_ijin,
          suratPermohonan: state?.detailDrone?.drone?.surat_assesment,
          suratIzinMiliter: state?.detailDrone?.drone?.surat_ijin,
          daftarPeralatan: state?.detailDrone?.drone?.daftar_peralatan,
          suratIzinOtoritas: state?.detailDrone?.drone?.surat_ijin,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="text-start">
            <Row className="m-0 p-0 mb-5">
              <Col className="m-0 p-0 me-auto">
                <h1 className="fs-3 fw-bold m-0 p-0">
                  Transponder #
                  {imei}
                </h1>
              </Col>

              <Col className="m-0 p-0 col-auto">
                <Button as={ReachLink} type="button" variant="outline-primary" className="px-4 me-3" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary" className="px-4">
                  {state.isLoadingSubmit ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : 'Save'}
                </Button>
              </Col>
            </Row>

            <Row className="w-100">
              <Tabs fill variant="pills" defaultActiveKey="option1">
                <Tab eventKey="option1" title="Drone Detail Form">
                  <Row className="w-100 m-0 mt-4 p-5 content">
                    <DroneDetailForm
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      touched={touched}
                      errors={errors}
                    />
                  </Row>
                </Tab>
                <Tab eventKey="option2" title="Upload License Files">
                  <Row className="w-100 m-0 mt-4 p-5 content">
                    <UploadLicenseFile
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      touched={touched}
                      errors={errors}
                    />
                  </Row>
                </Tab>
              </Tabs>
            </Row>
          </Form>
        )}
      </Formik>

    </DashboardLayoutUser>

  );
}

export default EditInputDetailUser;
