/* eslint-disable react/destructuring-assignment */
function ConvertISOtoDate(string) {
  if (string) {
    const [YYYY, MM, DD, HH, mm] = string.split(/[/:\-T]/);

    const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    return `${DD} ${bulan[parseInt(MM, 10) - 1]} ${YYYY}, ${HH}:${mm}`;
  }
  return null;
}

export default ConvertISOtoDate;
