import React, { useEffect, useReducer, useState } from 'react';
import { Formik } from 'formik';
import {
  Button,
  Col, Row, Stack, Form, InputGroup,
} from 'react-bootstrap';
import Rockblock from 'assets/detailDrone/rockblock.svg';
import { Link as ReachLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import DashboardLayoutAdmin from 'components/layout/DashboardLayoutAdmin';
import { getCookie, setCookie } from 'utils/SetCookies';
import axios from 'axios';
import { INITIAL_STATE, PermissionValidationAdminReducer } from 'reducer/Admin/PermissionValidationAdminReducer';
import LoadingPageAdmin from 'components/LoadingPageAdmin';
import AlertReact from 'components/alert/AlertReact';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ShowPDFFileBtn from 'components/buttonModal/ShowPDFFileBtn';

function DetailPermissionValidationAdmin() {
  const navigate = useNavigate();
  const { id, number } = useParams();

  const [state, dispatch] = useReducer(PermissionValidationAdminReducer, INITIAL_STATE);

  const [showAlert, setShowAlert] = useState(false);
  const [alertDetail, setAlertDetail] = useState({
    message: '', variant: '',
  });

  const auth = { token: getCookie('token') };

  const filePermissionValidation = [
    { name: 'Prosedur Kedaruratan', url: state?.detailAdmin?.prosedur_kedaruratan },
    { name: 'Dokumen Asuransi', url: state?.detailAdmin?.dokumen_asuransi },
    { name: 'Surat Permohonan dan assesment ruang udara', url: state?.detailAdmin?.surat_assesment },
    { name: 'Daftar peralatan yang dibawa', url: state?.detailAdmin?.daftar_peralatan },
    { name: 'Surat izin institusi', url: state?.detailAdmin?.surat_ijin },
  ];

  const schema = Yup.object().shape({
    verification_by: Yup.string().required('Verification By is required'),
    status: Yup.string().required('Status is required'),
    // remarks: Yup.string().required('Remarks is required'),
  });

  const fetchGetDetailPermission = async () => {
    axios({
      method: 'get',
      url: `permission-validation/details/${number}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'detailAdmin',
            data: res.data.data,
            isLoading: false,
          },
        });
      }
    });
  };

  const fetchSubmitVerify = async (values) => {
    axios({
      method: 'put',
      url: `permission-validation/verify/${id}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
      data: {
        verification_by: values.verification_by,
        status: values.status,
        remarks: values.remarks,
      },
    }).then(async (res) => {
      if (res) {
        console.log(res);
        const messageAlert = res?.data?.message;
        setAlertDetail({ message: messageAlert, variant: 'success' });
        setShowAlert(true);
        const timeId = setTimeout(() => {
          setShowAlert(false);
          navigate(-1);
        }, 1500);
        return () => {
          clearTimeout(timeId);
        };
      }
      return null;
    }).catch((res) => {
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      const messageAlert = res?.data?.message;
      setAlertDetail({ message: messageAlert, variant: 'danger' });
      setShowAlert(true);
      const timeId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    });
  };

  const handleFormSubmit = (values) => {
    fetchSubmitVerify(values);
  };

  useEffect(() => {
    setCookie('navName', 'Permission Validation');
    fetchGetDetailPermission();
  }, []);

  if (state.isLoading) {
    return <LoadingPageAdmin />;
  }
  return (
    <DashboardLayoutAdmin>
      <Row className="position-relative">
        <div className="position-fixed end-0 me-4" style={{ width: 'inherit' }}>
          <AlertReact message={alertDetail.message} showAlert={showAlert} setShowAlert={setShowAlert} variant={alertDetail.variant} />
        </div>
      </Row>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/admin/transponder-list">Transponder</a></li>
          <li className="breadcrumb-item"><a href="/admin/permission-validation">Permission Validation</a></li>
          <li className="breadcrumb-item active" aria-current="page">Detail Permission</li>
        </ol>
      </nav>

      <Row className="mb-1 align-items-center">
        <Col className="col-auto me-auto">
          <h1 className="fs-3 fw-bold m-0">
            Permission Validation
            {' '}
            {number}
          </h1>
        </Col>
      </Row>

      <h2 className="fs-4 m-0 mb-3">Verification and validation of licensing data</h2>

      <Row>
        <Col className="col-8 col-xl-5 col-xxl-4 mb-3">
          <LazyLoadImage
            className="w-100 rounded"
            alt="Drone Image"
            effect="blur"
            src={state?.detailAdmin?.drone_image ? state?.detailAdmin?.drone_image : Rockblock}
          />
        </Col>
        <Col className="col-12 col-xl-7">
          <h3 className="fs-5 fw-bold m-0">Transponder Rockblock</h3>
          <p className="fw-bold m-0 mb-3" style={{ color: '#666666' }}>
            {state?.detailAdmin?.imei_transponder}
            {' '}
            -
            {' '}
            {state?.detailAdmin?.serial_number_transponder}
          </p>

          <Row>
            {filePermissionValidation.map((item) => (
              <Row className="mb-4 w-100 align-items-center" key={item.name}>
                <Col className="col-9 me-auto d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#296DCD" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                  </svg>
                  <p className="m-0 ms-2 fw-semibold">{item.name}</p>
                </Col>
                <Col className="col-3 text-end">
                  <ShowPDFFileBtn
                    name={item.name}
                    fileURL={item.url}
                    title={item.name}
                  />
                </Col>
              </Row>
            ))}
          </Row>
        </Col>
      </Row>

      <Row className="m-0 p-3 rounded-3" style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)' }}>
        <h3>Verification Form</h3>

        <Formik
          enableReinitialize
          validationSchema={schema}
          onSubmit={(values) => handleFormSubmit(values)}
          initialValues={{
            verification_by: state.detailAdmin.verification_by || '',
            status: state.detailAdmin.status || '',
            remarks: state.detailAdmin.remarks || '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} className="text-start">
              <Row className="">
                <Col className="col-6 my-0 py-0">
                  <Row>
                    <Form.Group className="mb-3" controlId="verification_by">
                      <Form.Label className="fw-semibold">
                        Verification By
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Stack gap={1}>
                          <Form.Control
                            required
                            type="text"
                            className="border border-2 border-dark border-opacity-25"
                            placeholder="Verification by"
                            value={values.verification_by}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.verification_by && errors.verification_by ? <p className="error-text text-danger">{errors.verification_by}</p> : null}
                        </Stack>
                      </InputGroup>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group controlId="status">
                      <Form.Label className="fw-semibold">
                        Status
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Stack gap={1}>
                          <Form.Select className="border border-2 border-dark border-opacity-25" value={values.status} onChange={handleChange}>
                            <option hidden>Status</option>
                            <option value="accepted">Accepted</option>
                            <option value="revision">Revision</option>
                            <option value="rejected">Rejected</option>
                            <option value="not_validated_yet" hidden>Not Validated Yet</option>
                          </Form.Select>
                          {touched.status && errors.status ? <p className="error-text text-danger">{errors.status}</p> : null}
                        </Stack>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Col>
                <Col className="col-6 my-0 py-0 d-flex flex-column">
                  <Form.Group className=" flex-grow-1 d-flex flex-column" controlId="remarks">
                    <Form.Label className="fw-semibold">
                      Remarks
                      {/* <span className="text-danger"> *</span> */}
                    </Form.Label>
                    <InputGroup hasValidation className="flex-grow-1">
                      <Form.Control
                        as="textarea"
                        className="flex-grow-1 border border-2 border-dark border-opacity-25"
                        // required
                        type="text"
                        placeholder="Remarks..."
                        value={values.remarks}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {touched.remarks && errors.remarks ? <p className="error-text text-danger">{errors.remarks}</p> : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="m-0 mt-5">
                <div className="p-0 ms-auto w-auto">
                  <Button as={ReachLink} type="button" variant="outline-primary" className="px-4 me-3" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary" className="px-4">
                    Submit
                  </Button>
                </div>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>

    </DashboardLayoutAdmin>

  );
}

export default DetailPermissionValidationAdmin;
