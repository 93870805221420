import React from 'react';
import { Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import safeDrone from 'assets/map/droneIcon/safe-drone.png';

function MarkerHistoryTrack({
  data,
}) {
  const map = useMap();

  const drone = new L.Icon({
    iconUrl: safeDrone,
    iconSize: [40, 40],
  });
  return (
    <Marker
      position={[data?.latitude, data?.longitude]}
      icon={drone}
      eventHandlers={{
        click: (e) => { map.flyTo([e.latlng.lat, e.latlng.lng], 18); },
      }}
    />
  );
}

export default MarkerHistoryTrack;
