export const INITIAL_STATE = {
  id: '-',
  isLoading: false,
  operator_name: '-',
  pilot_name: '-',
  other_pilot_name: '-',
  flight_date: '-',
  total_flight_time: 0,
  nomer_perjanjian: '-',
  email: '-',
  notam_number: '-',
  deviation: '-',
  deviation_description: '-',
  status: '-',
  damage_list: [],
  other_damage: '-',
  abnormal_condition_list: [],
  other_abnormal_condition: '-',
};

export const PostFlightReportDetailReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_DATA':
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    default:
      return state;
  }
};
