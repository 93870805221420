import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import 'styles/LoadingPage.css';
import { getCookie } from 'utils/SetCookies';

function LoadingPageUser() {
  const navigate = useNavigate();
  const auth = { token: getCookie('token'), role: getCookie('role') };

  useEffect(() => {
    if (!auth.token) navigate('/');

    // nanti buat halaman 404
    if (auth.token && auth.role.toLowerCase() !== 'user') navigate('/not-found-page');
  }, []);

  return (
    <div className="loader text-center">
      <div className="loader-inner">

        <div className="lds-roller mb-3">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>

        <h4 className="text-uppercase font-weight-bold">Loading</h4>
      </div>
    </div>
  );
}

export default LoadingPageUser;
