import React, { useEffect, useReducer, useState } from 'react';
import {
  LayersControl,
  MapContainer, Polyline, ScaleControl,
} from 'react-leaflet';
import { BasemapLayer } from 'react-esri-leaflet';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import { useParams } from 'react-router';
import { INITIAL_STATE, FullMapsAdminReducer } from 'reducer/Admin/FullMapsAdminReducer';
import axios from 'axios';
import { getCookie } from 'utils/SetCookies';
import MarkerHistoryTrack from 'components/Map/MarkerHistoryTrack';
import LoadingPageAdmin from 'components/LoadingPageAdmin';
import {
  Button, Col, ProgressBar, Row,
} from 'react-bootstrap';
import PlaybackMaps from 'styles/Admin/PlaybackMaps.module.css';

function HistoryPlaybackAdmin() {
  const { id } = useParams();
  const [state, dispatch] = useReducer(FullMapsAdminReducer, INITIAL_STATE);
  const [isPlay, setIsPlay] = useState(false);
  const [index, setIndex] = useState(0);

  const auth = { token: getCookie('token') };

  const fetchGetPlayback = async () => {
    axios({
      method: 'get',
      url: `history/playback/${id}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      const dataBaru = res.data.data;

      dispatch({
        type: 'SET_DATA',
        payload: {
          name: 'posNow',
          data: dataBaru.map((item) => ({ latitude: item.latitude, longitude: item.longitude })),
        },
      });

      dispatch({ type: 'SET_IS_LOADING', payload: false });
    });
  };

  const fetchGetDetailHistory = async () => {
    axios({
      method: 'get',
      url: `history/${id}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      const dataBaru = res.data.data;

      dispatch({
        type: 'SET_DATA',
        payload: {
          name: 'detailHistory',
          data: dataBaru.detail_control,
        },
      });

      dispatch({ type: 'SET_IS_LOADING', payload: false });
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (index === state.posNow.length - 1 || !isPlay) {
        setIsPlay(false);
        clearInterval(intervalId);
      } else {
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'flightRoutes',
            data: [...state.flightRoutes, [state.posNow[index].latitude, state.posNow[index].longitude]],
          },
        });
        setIndex(index + 1);
      }
    }, 1000);

    return () => clearInterval(intervalId); // This is important
  });

  useEffect(() => {
    fetchGetPlayback();
    fetchGetDetailHistory();
  }, []);

  const handlePlay = () => {
    if (index === state.posNow.length - 1) {
      dispatch({
        type: 'SET_DATA',
        payload: {
          name: 'flightRoutes',
          data: [],
        },
      });
      setIndex(0);
    }
    setIsPlay(!isPlay);
  };

  const handleStop = () => {
    setIsPlay(false);
    setIndex(0);
    dispatch({
      type: 'SET_DATA',
      payload: {
        name: 'flightRoutes',
        data: [],
      },
    });
  };
  const handleNext = () => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        name: 'flightRoutes',
        data: [...state.flightRoutes, [state.posNow[index].latitude, state.posNow[index].longitude]],
      },
    });
    setIndex(index + 1);
  };

  const handlePrev = () => {
    const dataBaru = [...state.flightRoutes];
    dataBaru.pop();
    dispatch({
      type: 'SET_DATA',
      payload: {
        name: 'flightRoutes',
        data: dataBaru,
      },
    });
    setIndex(index - 1);
  };

  if (state.isLoading) {
    return <LoadingPageAdmin />;
  }
  return (
    <div className={`${PlaybackMaps.containerMap} vh-100 w-100}`}>
      <MapContainer
        className="containerMap vh-100"
        center={[state?.posNow[index].latitude, state?.posNow[index].longitude]}
        zoom={18}
        scrollWheelZoom
        fullscreenControl
      >
        <BasemapLayer name="ImageryLabels" />
        <BasemapLayer name="Imagery" />
        <ScaleControl />

        <Row className={`${PlaybackMaps.overlay} ${PlaybackMaps.boxControl}`}>
          <Col className={`${PlaybackMaps.content} col-5 p-3 rounded-3 text-white`}>
            <Row>
              <h1 className="fs-5">{state?.detailHistory[index]?.created_at}</h1>
            </Row>
            <Row className="w-100 mt-1 mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-left-circle me-3 pointer" viewBox="0 0 16 16" onClick={handlePrev}>
                  <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                </svg>

                <ProgressBar striped now={(index / (state.posNow.length - 1)) * 100} label={state?.detailHistory[index]?.created_at.split(' ')[3]} className="w-100 bg-dark fw-bold" variant="warning" />

                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right-circle ms-3 pointer" viewBox="0 0 16 16" onClick={handleNext}>
                  <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </div>
            </Row>
            <Row className="d-flex align-items-center justify-content-center w-100">
              <Col className="text-end">
                <p className="m-0 p-0 fs-6">
                  Altitude:
                  {' '}
                  {state?.detailHistory[index]?.altitude}
                  {' '}
                  m
                </p>
              </Col>
              <Col className="col-auto d-flex align-items-center justify-content-center">
                <Button className="m-0 p-0 me-3 bg-transparent border-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-stop-circle-fill text-danger pointer" viewBox="0 0 16 16" onClick={handleStop}>
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.5 5A1.5 1.5 0 0 0 5 6.5v3A1.5 1.5 0 0 0 6.5 11h3A1.5 1.5 0 0 0 11 9.5v-3A1.5 1.5 0 0 0 9.5 5h-3z" />
                  </svg>
                </Button>
                {isPlay ? (
                  <Button className="m-0 p-0 bg-transparent border-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-pause-circle" viewBox="0 0 16 16" onClick={handlePlay}>
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z" />
                    </svg>
                  </Button>
                ) : (
                  <Button className="m-0 p-0 bg-transparent border-0" disabled={isPlay}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-play-circle pointer" viewBox="0 0 16 16" onClick={handlePlay}>
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  </Button>
                )}
              </Col>
              <Col className="text-start">
                <p className="m-0 p-0 fs-6">
                  Ground Speed:
                  {' '}
                  {state?.detailHistory[index]?.ground_speed}
                  {' '}
                  m/s
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <LayersControl position="topright">
          <LayersControl.Overlay checked name="Track Layer">
            <Polyline
              pathOptions={{ color: '#39FF14', weight: 3 }}
              positions={state.flightRoutes}
            />
          </LayersControl.Overlay>
        </LayersControl>

        <MarkerHistoryTrack data={state?.posNow[index]} />
      </MapContainer>
    </div>
  );
}

export default HistoryPlaybackAdmin;
