/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

function PilotListUpdateStatusBtn({
  token, state, dispatch, id, status,
}) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const updateStatus = async () => {
    axios({
      method: 'put',
      url: `pilots/verify/${id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (res) {
        const dataBaru = state.dataAdmin.map((item) => {
          if (item._id === id) {
            return { ...item, status: 'verified' };
          }
          return item;
        });
        dispatch({
          type: 'UPDATE_DATA',
          payload: {
            name: 'dataAdmin',
            data: dataBaru,
          },
        });

        handleCloseModal();
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <Button variant="primary" disabled={status.toLowerCase() === 'verified'} onClick={handleShowModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
          </svg>
        </Button>
      </div>

      <Modal
        show={showModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center">
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you really want to verify this data? This process cannot be undone</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleShowModal}>Cancel</Button>
          <Button variant="primary" onClick={updateStatus}>Verified</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PilotListUpdateStatusBtn;
