import {
  Form, Table, Button, Row, Col, Stack, Image,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'styles/User/DetailHistoryUser.css';
import DashboardLayoutUser from 'components/layout/DashboardLayoutUser';
import Rockblock from 'assets/detailDrone/rockblock.svg';
import axios from 'axios';
import React, { useEffect, useReducer } from 'react';
import { INITIAL_STATE, HistoryDetailReducer } from 'reducer/User/HistoryDetailReducer';
import { getCookie, setCookie } from 'utils/SetCookies';
import LoadingPageUser from 'components/LoadingPageUser';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
    },
    zoom: {
      pan: {
        enabled: true,
        mode: 'xy',
        threshold: 5,
      },
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: 'xy',
      },
    },
  },
  maintainAspectRatio: false,
};

function DetailHistoryUser() {
  const { id } = useParams();
  const [state, dispatch] = useReducer(HistoryDetailReducer, INITIAL_STATE);
  const auth = { token: getCookie('token') };
  const fetchGetHistory = () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    axios({
      method: 'get',
      url: `history/${id}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        console.log(res);
        const dataHistory = res.data.data;
        const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'takeoff',
            data: dataHistory.detail_drone.total_takeoff,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'landing',
            data: dataHistory.detail_drone.total_landing,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'durasi',
            data: dataHistory.detail_drone.duration,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'avg_hours',
            data: dataHistory.detail_drone.average,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'min_hours',
            data: dataHistory.detail_drone.min_hours,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'max_hours',
            data: dataHistory.detail_drone.max_hours,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'nama_transponder',
            data: dataHistory.detail_transponder.name,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'imei',
            data: dataHistory.detail_transponder.imei,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'serial',
            data: dataHistory.detail_transponder.serial_number,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'validasi',
            data: dataHistory.detail_transponder.status_validasi,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'deskripsi',
            data: dataHistory.detail_transponder.description,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'pilot_name',
            data: dataHistory.detail_transponder.pilot_name ? dataHistory.detail_transponder.pilot_name : '-',
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'departure',
            data: dataHistory.detail_transponder.departure_time,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'arrival',
            data: dataHistory.detail_transponder.arrival_time,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'status',
            data: dataHistory.detail_transponder.status_history,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'ketersediaan',
            data: dataHistory.detail_transponder.available ? 'Available' : 'Unavailable',
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'labelChart',
            data: dataHistory.charts.labels,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'groundspeed',
            data: dataHistory.charts.datasets[0].data,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'altitude',
            data: dataHistory.charts.datasets[1].data,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'detailControl',
            data: dataHistory.detail_control.filter((item) => ({
              latitude: item.iridium_latitude, longitude: item.iridium_longitude, altitude: item.altitude, ground_speed: item.ground_speed, created_at: `${(`0${new Date(item.created_at).getDate()}`).slice(-2)} ${bulan[new Date(item.created_at).getMonth()]} ${new Date(item.created_at).getFullYear()}, ${new Date(item.created_at).getHours()}:${new Date(item.created_at).getMinutes()}`,
            })).reverse(),
          },
        });
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      }
    });
  };
  const labels = state.labelChart;
  const { groundspeed } = state;
  const { altitude } = state;
  const data = {
    labels,
    datasets: [
      {
        label: 'Ground Speed',
        data: groundspeed,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Altitude',
        data: altitude,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  useEffect(() => {
    if (auth.token) {
      setCookie('navName', 'History');
      fetchGetHistory();
    }
  }, []);
  function checkStatus(status) {
    if (status.toLowerCase() === 'finish') {
      return (<div className="status-finish text-center px-3 py-2 rounded">Finish</div>);
    }
    if (status.toLowerCase() === 'active') {
      return (<div className="status-active text-center px-3 py-2 rounded">Active</div>);
    }
    return (<div className="status-failed text-center px-3 py-2 rounded">Failed</div>);
  }

  if (state.isLoading) {
    return <LoadingPageUser />;
  }
  return (
    <DashboardLayoutUser>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/user">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/user/transponder-list">Transponder</a></li>
          <li className="breadcrumb-item"><a href="/user/history">History</a></li>
          <li className="breadcrumb-item active" aria-current="page">Detail History</li>
        </ol>
      </nav>

      <div className="box-atas">
        <Row>
          <Col className="col-12">
            <div>
              <h3 className="fw-bold">
                Detail History #
                {id}
              </h3>
            </div>
          </Col>
        </Row>
        <div className="box-atas">
          <Row>
            <Col className="col-auto col-xl-6 mb-3">
              <Image src={Rockblock} fluid className="rounded" alt="Drone Image" />
            </Col>
            <Col className="col-12 col-xl-6">
              <Row className="box-shadow-detail">
                <Col className="col-6">
                  <div className="p-5">
                    <p>Jumlah Take Off</p>
                    <p>Jumlah Landing</p>
                    <p>Durasi penerbangan</p>
                    <p>Average hours</p>
                    <p>Maximum hours</p>
                    <p>Minimum hours</p>
                  </div>
                </Col>
                <div className="col-6">
                  <div className="p-5">
                    <p>{ state.takeoff }</p>
                    <p>{ state.landing }</p>
                    <p>
                      { state.durasi }
                      {' '}
                      hours
                    </p>
                    <p>
                      { state.avg_hours }
                      {' '}
                      hours
                    </p>
                    <p>
                      { state.max_hours }
                      {' '}
                      hours
                    </p>
                    <p>
                      { state.min_hours }
                      {' '}
                      hours
                    </p>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
        <Row className="box-shadow-detail-2 mt-3 mb-5">
          <Col className="col-6">
            <Row className="row">
              <Col className="col-5">
                <div className="ps-5 pt-5">
                  <p>Nama Transponder</p>
                  <p>Imei Transponder</p>
                  <p>Serial Number</p>
                  <p>Status Validasi</p>
                  <p>Deskripsi</p>
                </div>
              </Col>
              <Col className="col-7">
                <div className="ps-5 pt-5">
                  <p>{ state.nama_transponder }</p>
                  <p>{ state.imei }</p>
                  <p>{ state.serial }</p>
                  <p>{ state.validasi }</p>
                  <p>{ state.deskripsi }</p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="col-6">
            <Row>
              <Col className="col-5">
                <div className="ps-5 pt-5">
                  <p>Nama Pilot</p>
                  <p>Waktu Kedatangan</p>
                  <p>Waktu Keberangkatan</p>
                  <p>Status</p>
                  <p>Ketersediaan</p>
                </div>
              </Col>
              <Col className="col-6">
                <div className="ps-5 pt-5">
                  <p>{ state.pilot_name }</p>
                  <p>{ state.departure }</p>
                  <p>{ state.arrival }</p>
                  <p>{ checkStatus(state.status) }</p>
                  <p>{ state.ketersediaan }</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="text-center align-content-center mb-5">
          <Col className="grafik">
            <div>
              <Line
                options={options}
                data={data}
                width={700}
                height={500}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="box-text">
        <Row>
          <Col className="col-6">
            <div>
              <h5 className="fw-bold">Detail Control</h5>
            </div>
          </Col>
        </Row>
      </div>
      <div className="box-text">
        <Row className="d-flex align-items-center">
          <Col className="col-auto me-auto">
            <div>
              <p className="m-0">Showing 1 to 1 of 2 entries</p>
            </div>
          </Col>
          <Col className="col-4">
            <div>
              <Form.Control
                type="text"
                id="searchBar"
                placeholder="Search..."
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="box-atas mt-3">
        <Row>
          <Col className="col-12">
            <Table hover className="align-middle width-table table-borderless w-100">
              <thead>
                <tr>
                  <th className="leftCornerTableRadius text-center">No</th>
                  <th className="text-center">Ground Speed</th>
                  <th className="text-center">Altitude</th>
                  <th className="text-center">Latitude</th>
                  <th className="text-center">Longitude</th>
                  <th className="px-5">See Maps</th>
                  <th className="rightCornerTableRadius px-3">Created At</th>
                </tr>
              </thead>
              <tbody>
                {state.detailControl.map((item, index) => (
                  <tr>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      {item.ground_speed}
                      {' '}
                      m/s
                    </td>
                    <td className="text-center">
                      {item.altitude}
                      {' '}
                      m
                    </td>
                    <td className="text-center">{item.iridium_latitude}</td>
                    <td className="text-center">{item.iridium_longitude}</td>
                    <td>
                      <Link className="idHistory fw-semibold px-5" to={`/user/maps/${item.iridium_latitude}/${item.iridium_longitude}`} target="_blank">
                        Lihat Peta
                      </Link>
                    </td>
                    <td>
                      {item.created_at}
                    </td>
                  </tr>
                ))}
                ;
              </tbody>
            </Table>
            <Row className="justify-content-center mb-5">
              <Col className="col-auto">
                <Stack direction="horizontal" className="d-flex" gap={3}>
                  <Button type="button" className="btn btn-primary d-flex align-items-center p-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    </svg>
                  </Button>
                  <p className="fw-semibold m-0">1 of 1</p>
                  <Button type="button" className="btn btn-primary d-flex align-items-center p-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </Button>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

    </DashboardLayoutUser>
  );
}

export default DetailHistoryUser;
