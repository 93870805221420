/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react';
import 'styles/User/DetailPostFlightReportUser.css';
import { Formik } from 'formik';
import {
  Button, Col, Row, Form, Card, InputGroup, Stack,
} from 'react-bootstrap';
import DashboardLayoutUser from 'components/layout/DashboardLayoutUser';
import { useParams, Link as ReachLink, useNavigate } from 'react-router-dom';
import { getCookie, setCookie } from 'utils/SetCookies';
import * as Yup from 'yup';
import LoadingPageUser from 'components/LoadingPageUser';
import { INITIAL_STATE, PostFlightReportDetailReducer } from 'reducer/User/PostFlightReportDetailReducer';
import ConvertISOtoDateField from 'utils/convertISOtoDateField';
import AlertReact from 'components/alert/AlertReact';

function DetailPostFlightReportUser() {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(PostFlightReportDetailReducer, INITIAL_STATE);
  const [showAlert, setShowAlert] = useState(false);
  const [alertDetail, setAlertDetail] = useState({
    message: '', variant: '',
  });
  const { id } = useParams();

  const auth = { token: getCookie('token') };

  const schema = Yup.object().shape({
    deviation: Yup.string().required('Field is required'),
    nomer_perjanjian: Yup.string().required('Nomor perijinan is required'),
    notam_number: Yup.string().required('Nomor notam is required'),
    notam: Yup.mixed().required('Notam is required').test('fileSize', 'File too large, max 5 Mb', (value) => value && value.size <= (5000000)),
    operator_name: Yup.string().required('Nama operator is required'),
    email: Yup.string().email('Not valid email format').required('Email is required'),
    pilot_name: Yup.string().required('Nama pilot is required'),
    total_flight_time: Yup.string().required('Total waktu terbang is required'),
    damage_list: Yup.string().required('Field is required'),
    other_damage: Yup.string().when('damage_list', {
      is: (val) => val === 7,
      then: Yup.string().required('Field is required'),
    }),
    abnormal_condition_list: Yup.string().required('Field is required'),
    other_abnormal_condition: Yup.string().when('abnormal_condition_list', {
      is: (val) => val === 5,
      then: Yup.string().required('Field is required'),
    }),
  });

  const kecelakaanFormData = [
    { id: 1, label: 'Kerusakan properti diluar PUTA' },
    { id: 2, label: 'Kerusakan fatal pada PUTA' },
    { id: 3, label: 'Potensi tabrakan dengan PUTA lain/Pesawat berawak lainnya' },
    { id: 4, label: 'Tabrakan dengan PUTA lain/Pesawat berawak lainnya' },
    { id: 5, label: 'Cedera Fatal' },
    { id: 6, label: 'Cedera serius' },
    { id: 7, label: 'Tidak Ada' },
    { id: 8, label: 'Lainnya' },
  ];

  const abnormalFormData = [
    { id: 1, label: 'Serangan atau gangguan burung dan cuaca' },
    { id: 2, label: 'Permasalahan Sistem Navigasi' },
    { id: 3, label: 'Permasalahan Powerplant/elektrikal' },
    { id: 4, label: 'Permasalahan Control System' },
    { id: 5, label: 'Tidak ada' },
    { id: 6, label: 'Lainnya' },
  ];

  const fetchGetPFR = () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    axios({
      method: 'get',
      url: `post-flight-report/details/${id}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        console.log(res);
        const hasil = res.data.data;
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'dataPFR',
            data: hasil,
          },
        });
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      }
    });
  };
  // eslint-disable-next-line no-unused-vars
  const fetchUpdatePFR = async (values) => {
    await axios({
      method: 'put',
      url: `post-flight-report/update/${state?.dataPFR?._id}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
      data: {
        nomer_perjanjian: values.nomer_perjanjian,
        notam_number: values.notam_number,
        notam: values.notam,
        deviation: values.deviation,
        deviation_description: values.deviation_description,
        operator_name: values.operator_name,
        email: values.email,
        pilot_name: values.pilot_name,
        other_pilot_name: values.other_pilot_name,
        flight_date: values.flight_date,
        total_flight_time: values.total_flight_time,
        damage_list: [{
          key: 'damages',
          datas: kecelakaanFormData.map((item, index) => ({ value: item.label, isSelected: values.damage_list === index })),
        }],
        abnormal_condition_list: [{
          key: 'abnormal_operating_conditions',
          datas: abnormalFormData.map((item, index) => ({ value: item.label, isSelected: values.abnormal_condition_list === index })),
        }],
        other_damage: values.other_damage,
        other_abnormal_condition: values.other_abnormal_condition,
      },
    }).then((res) => {
      if (res) {
        dispatch({ type: 'SET_IS_LOADING_SUBMIT', payload: false });
        console.log(JSON.stringify(res.data));
        const messageAlert = res?.data?.message;
        setAlertDetail({ message: messageAlert, variant: 'success' });
        setShowAlert(true);
        const timeId = setTimeout(() => {
          setShowAlert(false);
          navigate(-1);
        }, 1500);

        return () => {
          clearTimeout(timeId);
        };
      }
      return null;
    }).catch((res) => {
      dispatch({ type: 'SET_IS_LOADING_SUBMIT', payload: false });
      const messageAlert = res?.data?.message;
      setAlertDetail({ message: messageAlert, variant: 'danger' });
      setShowAlert(true);
      const timeId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    });
  };

  function showButton() {
    const CurrStatus = state.status;
    if (CurrStatus === 'accepted') {
      return (
        <Button variant="primary" type="submit" className="w-40 ms-2" disabled>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <span>Save & Submit</span>
          </div>
        </Button>
      );
    }
    if (CurrStatus === 'not-filled-yet') {
      return (
        <Button variant="primary" type="submit" className="w-40 ms-2" onClick="fetchEditStatus('waiting')">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <span>Save & Submit</span>
          </div>
        </Button>
      );
    }
    return (
      <Button variant="primary" type="submit" className="w-40 ms-2">
        <div className="d-flex flex-row justify-content-center align-items-center">
          <span>Save & Submit</span>
        </div>
      </Button>
    );
  }
  const handleFormSubmit = (values) => {
    console.log(values);
    fetchUpdatePFR(values);
  };
  useEffect(() => {
    if (auth.token) {
      setCookie('navName', 'Post Flight Report');
      fetchGetPFR();
    }
  }, []);

  console.log(state.dataPFR);

  if (state.isLoading) {
    return <LoadingPageUser />;
  }
  return (
    <DashboardLayoutUser>
      <Row className="position-relative" style={{ zIndex: 9999 }}>
        <div className="position-fixed end-0 me-4" style={{ width: 'inherit' }}>
          <AlertReact message={alertDetail.message} showAlert={showAlert} setShowAlert={setShowAlert} variant={alertDetail.variant} />
        </div>
      </Row>

      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/user">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/user/transponder-list">Transponder</a></li>
          <li className="breadcrumb-item"><a href="/user/post-flight-report">Post Flight Report</a></li>
          <li className="breadcrumb-item active" aria-current="page">Detail Post Flight Report</li>
        </ol>
      </nav>
      <Card body>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => handleFormSubmit(values)}
          initialValues={{
            nomer_perjanjian: state.dataPFR.nomer_perjanjian ? state?.dataPFR?.nomer_perjanjian : '',
            notam_number: state.dataPFR.notam_number ? state?.dataPFR?.notam_number : '',
            notam: undefined,
            deviation: state.dataPFR.deviation ? state?.dataPFR?.deviation : '',
            deviation_description: state.dataPFR.deviation_description ? state?.dataPFR?.deviation_description : '',
            operator_name: state.dataPFR.operator_name ? state?.dataPFR?.operator_name : '',
            email: state.dataPFR.email ? state?.dataPFR?.email : '',
            pilot_name: state.dataPFR.pilot_name ? state?.dataPFR?.pilot_name : '',
            other_pilot_name: state.dataPFR.other_pilot_name ? state?.dataPFR?.other_pilot_name : '',
            flight_date: state.dataPFR.flight_date ? state?.dataPFR?.flight_date : '',
            total_flight_time: state?.dataPFR?.total_flight_time === 0 || state?.dataPFR?.total_flight_time ? parseInt(state.dataPFR.total_flight_time, 10) : null,
            damage_list: '',
            other_damage: '',
            abnormal_condition_list: '',
            other_abnormal_condition: '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} className="text-start">
              <Row className="mb-3">
                <Col className="col-auto me-auto">
                  <h1 className="fs-2 fw-bold">
                    Post Flight Report #
                    {id}
                  </h1>
                  <p className="fs-5">Review the post flight report ensure the safety of people around you.</p>
                </Col>
                <Col className="col-3 ms-auto">
                  <Button as={ReachLink} type="button" variant="outline-primary" className="w-40" onClick={() => navigate(-1)}>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <span>
                        Cancel
                      </span>
                    </div>
                  </Button>
                  {showButton()}
                </Col>
              </Row>
              <Row>
                <h1 className="fs-4 fw-bold">Post Flight Report Form</h1>
                <Col className="col-5 me-auto">
                  <Form.Group className="mb-3" controlId="operator_name">
                    <Form.Label className="fw-semibold">
                      Nama Operator
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Nama Operator.."
                          value={values.operator_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.operator_name && errors.operator_name ? <p className="error-text text-danger">{errors.operator_name}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="pilot_name">
                    <Form.Label className="fw-semibold">
                      Nama Remote Pilot
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Nama Remote Pilot.."
                          value={values.pilot_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.pilot_name && errors.pilot_name ? <p className="error-text text-danger">{errors.pilot_name}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="other_pilot_name">
                    <Form.Label className="fw-semibold">
                      Nama Remote Pilot Lainnya
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Nama Remote Pilot Lainnya.."
                          value={values.other_pilot_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.other_pilot_name && errors.other_pilot_name ? <p className="error-text text-danger">{errors.other_pilot_name}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-semibold">
                      Tanggal Terbang
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          disabled
                          required
                          type="date"
                          name="duedate"
                          placeholder="Tanggal Terbang.."
                          value={ConvertISOtoDateField(values.flight_date)}
                        />
                        {touched.flight_date && errors.flight_date ? <p className="error-text text-danger">{errors.flight_date}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="total_flight_time">
                    <Form.Label className="fw-semibold">
                      Total Waktu Terbang (menit)
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="number"
                          placeholder="Total Waktu Terbang.."
                          value={values.total_flight_time}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.total_flight_time && errors.total_flight_time ? <p className="error-text text-danger">{errors.total_flight_time}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="kecelekaanFormData">
                    <Form.Label className="fw-semibold">
                      Kejadian kecelakaan/insiden serius
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        {kecelakaanFormData.map((item, index) => (
                          <Form.Check
                            type="radio"
                            name="kecelekaanFormData"
                            id={`${item.id}kecelekaanFormData`}
                            label={item.label}
                            aria-label={item.label}
                            onChange={() => setFieldValue('damage_list', index)}
                            value={values.damage_list}
                          />
                        ))}
                        {touched.damage_list && errors.damage_list ? <p className="error-text text-danger">{errors.damage_list}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="other_damage">
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          disabled={values.damage_list !== 7}
                          required
                          type="text"
                          placeholder="Other..."
                          value={values.other_damage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Stack>
                      {touched.other_damage && errors.other_damage ? <p className="error-text text-danger">{errors.other_damage}</p> : null}
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col className="col-5 me-auto">
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label className="fw-semibold">
                      Email
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Email.."
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email ? <p className="error-text text-danger">{errors.email}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="notam_number">
                    <Form.Label className="fw-semibold">
                      Notam Number
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Notam Number.."
                          value={values.notam_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.notam_number && errors.notam_number ? <p className="error-text text-danger">{errors.notam_number}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="notam">
                    <Form.Label className="fw-semibold">
                      Notam
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="file"
                          placeholder="(316) 555-0116"
                          value={undefined}
                          onChange={(e) => setFieldValue('notam', e.currentTarget.files[0])}
                          onBlur={handleBlur}
                          accept=".pdf"
                        />
                        {touched.notam && errors.notam ? <p className="error-text text-danger">{errors.notam}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="nomer_perjanjian">
                    <Form.Label className="fw-semibold">
                      Nomor Perijinan Pengoperasian
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Nomor Perijinan Pengoperasian.."
                          value={values.nomer_perjanjian}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.nomer_perjanjian && errors.nomer_perjanjian ? <p className="error-text text-danger">{errors.nomer_perjanjian}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="deviation">
                    <Form.Label className="fw-semibold">
                      Apakah penerbangan Anda menyimpang dari perijinan/persetujuan yang telah diberikan?
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Ya/Tidak"
                          value={values.deviation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.deviation && errors.deviation ? <p className="error-text text-danger">{errors.deviation}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="deviation_description">
                    <Form.Label className="fw-semibold">
                      Deskripsi Penyimpangan
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Ya/Tidak"
                          value={values.deviation_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.deviation_description && errors.deviation_description ? <p className="error-text text-danger">{errors.deviation_description}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="abnormal_condition_list">
                    <Form.Label className="fw-semibold">
                      Pengoperasian Pada Kondisi Tidak Normal
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        {abnormalFormData.map((item, index) => (
                          <Form.Check
                            type="radio"
                            name="abnormalFormData"
                            id={`${item.id}abnormalFormData`}
                            label={item.label}
                            aria-label={item.label}
                            onChange={() => setFieldValue('abnormal_condition_list', index)}
                            value={values.abnormal_condition_list}
                          />
                        ))}
                        {touched.abnormal_condition_list && errors.abnormal_condition_list ? <p className="error-text text-danger">{errors.abnormal_condition_list}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="other_abnormal_condition">
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          disabled={values.abnormal_condition_list !== 5}
                          required
                          type="text"
                          placeholder="Other..."
                          value={values.other_abnormal_condition}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.other_abnormal_condition && errors.other_abnormal_condition ? <p className="error-text text-danger">{errors.other_abnormal_condition}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayoutUser>
  );
}

export default DetailPostFlightReportUser;
