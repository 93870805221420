import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'App.css';

import Login from 'views/Login';

// User
import RegisterUser from 'views/User/RegisterUser';
import DashboardUser from 'views/User/DashboardUser';
import TransponderListUser from 'views/User/TransponderList/TransponderListUser';
import DetailDroneUser from 'views/User/TransponderList/DetailDroneUser';
import PilotList from 'views/User/PilotListUser';
import PostFlightReportUser from 'views/User/PostFlightReport/PostFlightReportUser';
import DetailPostFlightReportUser from 'views/User/PostFlightReport/DetailPostFlightReportUser';
import MonitoringUser from 'views/User/Monitoring/MonitoringUser';
import DetailMonitoringUser from 'views/User/Monitoring/DetailMonitoringUser';
import HistoryUser from 'views/User/History/HistoryUser';
import DetailHistoryUser from 'views/User/History/DetailHistoryUser';
import ProfileUser from 'views/User/ProfileUser';

// Admin
import DashboardAdmin from 'views/Admin/DashboardAdmin';
import TransponderListAdmin from 'views/Admin/TransponderList/TransponderListAdmin';
import DetailDroneAdmin from 'views/Admin/TransponderList/DetailDroneAdmin';
import PilotListAdmin from 'views/Admin/PilotListAdmin';
import PermissionValidationAdmin from 'views/Admin/PermissionValidation/PermissionValidationAdmin';
import DetailPermissionValidationAdmin from 'views/Admin/PermissionValidation/DetailPermissionValidationAdmin';
import PostFlightReportAdmin from 'views/Admin/PostFlightReport/PostFlightReportAdmin';
import DetailPostFlightReportAdmin from 'views/Admin/PostFlightReport/DetailPostFlightReportAdmin';
import HistoryAdmin from 'views/Admin/History/HistoryAdmin';
import DetailHistoryAdmin from 'views/Admin/History/DetailHistoryAdmin';
import AdminList from 'views/Admin/AdminList';
import UserList from 'views/Admin/UserList';
import NotFoundPage from 'components/NotFoundPage';
import MonitoringAdmin from 'views/Admin/Monitoring/MonitoringAdmin';
import DetailMonitoringAdmin from 'views/Admin/Monitoring/DetailMonitoringAdmin';
import FullscreenMaps from 'views/Admin/FullscreenMaps';
import HistoryPlaybackAdmin from 'views/Admin/History/HistoryPlaybackAdmin';
import ProfileAdmin from 'views/Admin/ProfileAdmin';
import InputDetailUser from 'views/User/TransponderList/InputDetailUser';
import EditInputDetailUser from 'views/User/TransponderList/EditInputDetailUser';
// Testing
// import Grafik from 'views/Admin/Grafik';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/user" element={<DashboardUser />} />
        <Route path="/user/register" element={<RegisterUser />} />
        <Route path="/user/transponder-list" element={<TransponderListUser />} />
        <Route path="/user/transponder-list/detail-drone/:imei" element={<DetailDroneUser />} />
        <Route path="/user/transponder-list/input-detail/:imei/:id" element={<InputDetailUser />} />
        <Route path="/user/transponder-list/edit-detail/:imei/:id" element={<EditInputDetailUser />} />
        <Route path="/user/pilot-list" element={<PilotList />} />
        <Route path="/user/post-flight-report" element={<PostFlightReportUser />} />
        <Route path="/user/post-flight-report/detail-post-flight-report/:id" element={<DetailPostFlightReportUser />} />
        <Route path="/user/monitoring" element={<MonitoringUser />} />
        <Route path="/user/monitoring/detail-monitoring/:number" element={<DetailMonitoringUser />} />
        <Route path="/user/history" element={<HistoryUser />} />
        <Route path="/user/history/detail-history/:id" element={<DetailHistoryUser />} />
        <Route path="/user/maps/:latitude/:longitude" element={<FullscreenMaps />} />
        <Route path="/user/profile" element={<ProfileUser />} />

        <Route path="/admin" element={<DashboardAdmin />} />
        <Route path="/admin/transponder-list" element={<TransponderListAdmin />} />
        <Route path="/admin/transponder-list/detail-drone/:imei" element={<DetailDroneAdmin />} />
        <Route path="/admin/pilot-list" element={<PilotListAdmin />} />
        <Route path="/admin/permission-validation" element={<PermissionValidationAdmin />} />
        <Route path="/admin/permission-validation/detail-permission/:id/:number" element={<DetailPermissionValidationAdmin />} />
        <Route path="/admin/monitoring" element={<MonitoringAdmin />} />
        <Route path="/admin/monitoring/detail-monitoring/:number" element={<DetailMonitoringAdmin />} />
        <Route path="/admin/post-flight-report" element={<PostFlightReportAdmin />} />
        <Route path="/admin/post-flight-report/detail-post-flight-report/:id" element={<DetailPostFlightReportAdmin />} />
        <Route path="/admin/history" element={<HistoryAdmin />} />
        <Route path="/admin/history/detail-history/:id" element={<DetailHistoryAdmin />} />
        <Route path="/admin/history/playback/:id" element={<HistoryPlaybackAdmin />} />
        <Route path="/admin/admin-list" element={<AdminList />} />
        <Route path="/admin/user-list" element={<UserList />} />
        <Route path="/admin/profile" element={<ProfileAdmin />} />

        <Route path="/admin/maps/:latitude/:longitude" element={<FullscreenMaps />} />
        {/* <Route path="/grafik" element={<Grafik />} /> */}
        <Route path="/not-found-page" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
