import React from 'react';
import { Link } from 'react-router-dom';
import 'styles/User/SidebarUser.css';
import longLogo from 'assets/longLogo.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function SidebarUser() {
  return (
    <div className="sidebar px-3">
      <div className="logoWrapper d-flex align-items-center justify-content-center">
        <LazyLoadImage
          visibleByDefault
          alt="Logo"
          src={longLogo}
          height="25px"
        />
      </div>
      <div className="linkWrapper text-white">
        <Link to="/user" className="sideLink d-flex align-items-center mb-3 fw-bold">
          <div className="icon">
            <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8.49988L10 1.49988L19 8.49988V19.4999C19 20.0303 18.7893 20.539 18.4142 20.9141C18.0391 21.2892 17.5304 21.4999 17 21.4999H3C2.46957 21.4999 1.96086 21.2892 1.58579 20.9141C1.21071 20.539 1 20.0303 1 19.4999V8.49988Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <p className="m-0 ms-3">Dashboard</p>
        </Link>
        <div className="transponder">
          <div className="sideLinkTitle d-flex align-items-center mb-3 fw-bold">
            <div className="icon">
              <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 9.75V6.75H19.5V8.25H4.5V6.75H3V9.75H8.25V13.059L5.25 14.7278V18H6.75V15.609L9.195 14.25H14.8057L17.25 15.6098V18H18.75V14.7278L15.75 13.059V9.75H21ZM14.25 12.75H9.75V9.75H14.25V12.75ZM0 3.75H7.5V5.25H0V3.75ZM16.5 3.75H24V5.25H16.5V3.75ZM12 1.32635e-06C11.4091 -0.000214874 10.8241 0.116283 10.2783 0.342807C9.73262 0.569331 9.23702 0.901418 8.82 1.32L9.87975 2.37975C10.158 2.10096 10.4885 1.87978 10.8523 1.72887C11.2161 1.57795 11.6061 1.50028 12 1.50028C12.3939 1.50028 12.7839 1.57795 13.1477 1.72887C13.5115 1.87978 13.842 2.10096 14.1202 2.37975L15.1807 1.31925C14.7636 0.900681 14.2679 0.568658 13.722 0.342259C13.1762 0.11586 12.5909 -0.000453088 12 1.32635e-06V1.32635e-06Z" fill="white" />
              </svg>
            </div>
            <p className="m-0 ms-3">Transponder</p>
          </div>
          <Link to="/user/transponder-list" className="sideLink d-flex align-items-center mb-3">
            <div className="icon" />
            <p className="m-0 ms-3">Transponder List</p>
          </Link>
          <Link to="/user/pilot-list" className="sideLink d-flex align-items-center mb-3">
            <div className="icon" />
            <p className="m-0 ms-3">Pilot List</p>
          </Link>
          <Link to="/user/monitoring" className="sideLink d-flex align-items-center mb-3">
            <div className="icon" />
            <p className="m-0 ms-3">Monitoring</p>
          </Link>
          <Link to="/user/post-flight-report" className="sideLink d-flex align-items-center mb-3">
            <div className="icon" />
            <p className="m-0 ms-3">Post Flight Report</p>
          </Link>
          <Link to="/user/history" className="sideLink d-flex align-items-center mb-3">
            <div className="icon" />
            <p className="m-0 ms-3">History</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SidebarUser;
