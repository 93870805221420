export const INITIAL_STATE = {
  isLoading: false,
  dataAdmin: [],
  detailMonitoring: {},
  dataPreparation: {},
  dataLanding: {},
};

export const MonitoringReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_DATA':
      return {
        ...state,
        [action.payload.name]: action.payload.data,
        isLoading: action.payload.isLoading,
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    default:
      return state;
  }
};
