export const INITIAL_STATE = {
  isLoading: false,
  pfrList: [],
};

export const PostFlightReportListReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_DATA':
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    default:
      return state;
  }
};
