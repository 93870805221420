import React from 'react';
import {
  Col, InputGroup, Row, Stack, Form,
} from 'react-bootstrap';

function DroneDetailForm({
  handleChange, handleBlur, values,
  touched, errors,
}) {
  const fieldData = [
    { id: 'droneName', name: 'Drone Name', required: true },
    { id: 'serialNumber', name: 'Serial Number', required: true },
    { id: 'weightWithoutPayload', name: 'Weight Without Payload', required: true },
    { id: 'cruiseSpeed', name: 'Cruise Speed', required: true },
    { id: 'maxRateOfClimb', name: 'Max Rate of Climb', required: true },
    { id: 'maxTakeOffWeight', name: 'Maximum Take Off Weight', required: true },
    { id: 'maxFlightRange', name: 'Maximum FlightRange', required: true },
    { id: 'maxSpeed', name: 'Maximum Speed', required: true },
    { id: 'maxCruiseHeight', name: 'Maximum Cruise Height', required: true },
    { id: 'operationalPayloadWeight', name: 'Operational Payload Weight', required: true },
    { id: 'volumetricPayload', name: 'Volumetric Payload', required: false },
    { id: 'wingMaterial', name: 'Wing Material', required: false },
    { id: 'fuselageMaterial', name: 'Fuselage Material', required: false },
    { id: 'fileSaveSystem', name: 'File Save System', required: false },
    { id: 'controlSystem', name: 'Control System', required: false },
    { id: 'proximitySensors', name: 'Proximity Sensors', required: false },
    { id: 'precisionLoading', name: 'Precision Loading', required: false },
    { id: 'operationSystem', name: 'Operation System', required: false },
    { id: 'communicationSystem', name: 'Communication System', required: false },
    { id: 'description', name: 'Description', required: false },
  ];
  return (
    <>
      <h1 className="fs-5 text-black">Drone Detail Form</h1>

      <Row>
        {fieldData.map((item) => (
          <Col className="col-6 text-black" key={item.id}>
            <Form.Group className="mb-3" controlId={item.id}>
              <Form.Label className="fw-semibold">
                {item.name}
                {item.required
                      && <span className="text-danger"> *</span>}
              </Form.Label>
              <Stack gap={1}>
                <InputGroup hasValidation>
                  <Form.Control
                    required
                    type="text"
                    placeholder={`Enter ${item.name} field`}
                    value={values[item.id]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputGroup>
                {item.required
                    && touched[item.id] && errors[item.id] ? <p className="error-text text-danger">{errors[item.id]}</p> : null}
              </Stack>
            </Form.Group>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default DroneDetailForm;
