import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useReducer, useState } from 'react';
import {
  Button, Col, Container, Form, InputGroup, Row, Stack,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { INITIAL_STATE, LoginReducer } from 'reducer/LoginReducer';
import 'styles/Login.css';
import AlertReact from 'components/alert/AlertReact';
import { getCookie, setCookie } from 'utils/SetCookies';
import * as Yup from 'yup';

function Login() {
  const auth = { token: getCookie('token'), role: getCookie('role') };
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(LoginReducer, INITIAL_STATE);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');

  useEffect(() => {
    if (auth.token && auth.role.toLowerCase() === 'admin') navigate('/admin');
    else if (auth.token && auth.role.toLowerCase() === 'user') navigate('/user');
  }, []);

  const handleShowPassword = () => dispatch({ type: 'SET_SHOW_PASSWORD' });

  const handleChangeCheckbox = (e) => {
    dispatch({
      type: 'SET_REMEMBER',
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const schema = Yup.object().shape({
    email: Yup.string().email('Not valid email format').required('Email is required'),
    password: Yup.string().min(8, 'Must be 8 characters or more').required('Password is required'),
  });

  const fetchLogin = (val, actions) => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    axios({
      method: 'post',
      url: 'auth/login',
      data: {
        email: val.email,
        password: val.password,
      },
    }).then((res) => {
      if (res) {
        setCookie('token', res.data.data.token);
        setCookie('role', res.data.data.role);
        if (res.data.data.role.toLowerCase() === 'admin') navigate('/admin');
        else if (res.data.data.role.toLowerCase() === 'user') navigate('/user');
      }
    }).catch((res) => {
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      const message = res?.response?.data?.message;
      setMessageAlert(message);
      setShowAlert(true);
      if (message.toLowerCase() === 'incorrect password') {
        console.log('incorrect password');
        actions.resetForm({
          values: {
            email: val.email,
            password: '',
          },
        });
      } else actions.resetForm();
      const timeId = setTimeout(() => {
        setShowAlert(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    });
  };

  const handleFormSubmit = (values, actions) => {
    fetchLogin(values, actions);
  };

  return (
    <Container fluid className="vh-100">
      <Row className="h-100">
        <Col className="bgLogin d-none d-lg-block" />
        <Col className="d-flex align-items-center justify-content-center">
          <Col xs={12} md={11} lg={8}>
            <AlertReact message={messageAlert} showAlert={showAlert} setShowAlert={setShowAlert} />
            <div className="text-center">
              <h1 className="fw-bold">UAVITS</h1>
              <p>UAVITS Radar helps you monitor the drone position in real time</p>
            </div>
            <Formik
              validationSchema={schema}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
              initialValues={{
                email: '',
                password: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="text-start">
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email ? <p className="error-text text-danger">{errors.email}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="m-0" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Stack gap={1}>
                      <InputGroup hasValidation className="position-relative d-flex align-items-center">
                        <Form.Control
                          required
                          type={state.showPassword ? 'text' : 'password'}
                          placeholder="Enter password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputGroup.Text className="bg-transparent position-absolute border-0 end-0">
                          {state.showPassword
                            ? (
                              <svg id="tooglePassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16" onClick={handleShowPassword}>
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                              </svg>
                            )
                            : (
                              <svg id="tooglePassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16" onClick={handleShowPassword}>
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                              </svg>
                            )}
                        </InputGroup.Text>
                      </InputGroup>
                      {touched.password && errors.password ? <p className="error-text text-danger">{errors.password}</p> : null}
                    </Stack>
                  </Form.Group>

                  <Row className="mt-3 mb-3">
                    <Col>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" onChange={handleChangeCheckbox} />
                      </Form.Group>
                    </Col>
                    <Col className="text-end">
                      <p className="fw-bold text-danger">Forgot Password?</p>
                    </Col>
                  </Row>

                  <Button className="w-100 fw-semibold mb-4" type="submit" variant="primary" size="lg">
                    {state.isLoading
                      ? (
                        <div>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                          Loading...
                        </div>
                      )
                      : <span>Login</span>}
                  </Button>

                  <Row>
                    <Link to="/user/register" className="text-center fw-bold">
                      Don’t have an account ? Sign Up
                    </Link>
                  </Row>
                </Form>

              )}
            </Formik>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
