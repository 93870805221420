import React, { useEffect } from 'react';
import { Stack } from 'react-bootstrap';
import 'styles/User/DashboardLayoutUser.css';
import SidebarUser from 'components/SidebarUser';
import NavbarUser from 'components/NavbarUser';
import { useNavigate } from 'react-router-dom';
import { getCookie } from 'utils/SetCookies';

function DashboardLayoutUser({ children }) {
  const navigate = useNavigate();
  const auth = { token: getCookie('token'), role: getCookie('role') };
  useEffect(() => {
    if (!auth.token) navigate('/');

    if (auth.token && auth.role.toLowerCase() !== 'user') navigate('/not-found-page');
  }, []);

  if (auth.token && auth.role.toLowerCase() === 'user') {
    return (
      <Stack direction="horizontal" className="body-wrapper align-items-start w-100 vh-100" gap={0}>
        <SidebarUser />
        <div className="w-100 justify-content-start">
          <NavbarUser />
          <div className="main-content pt-3 px-5">
            {children}
          </div>
        </div>
      </Stack>
    );
  }
}

export default DashboardLayoutUser;
