/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
import React, { useEffect, useReducer, useState } from 'react';
import {
  Row, Tab, Tabs, Col, Stack, InputGroup, Form, Button,
} from 'react-bootstrap';
import DashboardLayoutUser from 'components/layout/DashboardLayoutUser';
import { useParams } from 'react-router-dom';
import LoadingPageUser from 'components/LoadingPageUser';
import { getCookie, setCookie } from 'utils/SetCookies';
import { INITIAL_STATE, MonitoringReducer } from 'reducer/User/MonitoringReducer';
import 'styles/User/DetailMonitoringUser.css';
import Steps from 'rsuite/Steps';
import 'rsuite/dist/rsuite.min.css';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import GetTimeNow from 'utils/GetTimeNow';

function DetailMonitoringUser() {
  const { number } = useParams();

  const auth = { token: getCookie('token') };

  const [state, dispatch] = useReducer(MonitoringReducer, INITIAL_STATE);

  const [step, setStep] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const updateChecklist = (stepIndex) => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    axios({
      method: 'put',
      url: `monitoring/checklist/preparation/${id}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
      index: stepIndex,
      time: GetTimeNow(),
    }).then((res) => {
      if (res) {
        const dataBaru = res.data.data;
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'detailUser',
            data: dataBaru,
            isLoading: false,
          },
        });
        dataBaru?.monitorings.forEach((item) => {
          if (item.key.toLowerCase() === 'preparation') {
            dispatch({
              type: 'SET_DATA',
              payload: {
                name: 'dataPreparation',
                data: item,
                isLoading: false,
              },
            });
          } else if (item.key.toLowerCase() === 'landing') {
            dispatch({
              type: 'SET_DATA',
              payload: {
                name: 'dataLanding',
                data: item,
                isLoading: false,
              },
            });
          }
          return item;
        });
      }
    });
  };

  const onChange = (nextStep) => {
    console.log(nextStep);
    if (nextStep > state.dataPreparation.length - 1) {
      setStep(state.dataPreparation.length - 1);
    } else {
      setStep(nextStep);
    }
    const index = nextStep - 1;
    const time = GetTimeNow();
    // console.log(state.dataPreparation
    // updateChecklist(index);

    console.log(index, time);
  };

  const onNext = () => onChange(step + 1);
  // const onPrevious = () => onChange(step - 1);

  const schema = Yup.object().shape({
    origin: Yup.string().required('Origin is required'),
    destination: Yup.string().required('Destination is required'),
    pilot_name: Yup.string().required('Pilot Name is required'),
    kml: Yup.string().required('KML is required'),
  });

  const fetchGetDetailMonitoring = () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    axios({
      method: 'get',
      url: `monitoring/fetch/${number}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        const dataBaru = res.data.data;
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'detailUser',
            data: dataBaru,
            isLoading: false,
          },
        });
        dataBaru?.monitorings.forEach((item) => {
          if (item.key.toLowerCase() === 'preparation') {
            dispatch({
              type: 'SET_DATA',
              payload: {
                name: 'dataPreparation',
                data: item,
                isLoading: false,
              },
            });
          } else if (item.key.toLowerCase() === 'landing') {
            dispatch({
              type: 'SET_DATA',
              payload: {
                name: 'dataLanding',
                data: item,
                isLoading: false,
              },
            });
          }
          return item;
        });
      }
    });
  };

  useEffect(() => {
    if (auth.token) {
      setCookie('navName', 'Monitoring');
      fetchGetDetailMonitoring();
    }
  }, []);

  const styles = {
    width: '200px',
    display: 'inline-table',
    verticalAlign: 'top',
  };

  if (state.isLoading) {
    return <LoadingPageUser />;
  }
  return (
    <DashboardLayoutUser>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/user">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/user/transponder-list">Transponder List</a></li>
          <li className="breadcrumb-item"><a href="/user/monitoring">Monitoring</a></li>
          <li className="breadcrumb-item active" aria-current="page">Detail Monitoring</li>
        </ol>
      </nav>

      <h1 className="fs-3 fw-bold m-0 mb-2">
        Monitoring #
        {number}
      </h1>

      <h2 className="fs-5 m-0 mb-5">
        Preparation before take off
      </h2>

      <Row className="w-100">
        <Tabs fill variant="pills" defaultActiveKey="option1">
          <Tab eventKey="option1" title="Preparation & Take Off">
            <Row className="w-100 m-0 mt-4 p-5 content">
              <Col className="col-6">
                <div className="position-relative start-100 ps-5 pt-5 translate-middle">
                  <Button onClick={onNext} disabled={step === state.dataPreparation.length - 1}>
                    Next
                  </Button>
                </div>
                <Steps current={step} vertical style={styles}>
                  {state?.dataPreparation?.datas?.map((item) => <Steps.Item key={item._id} title={item.time ? item?.time : '-'} description={item?.value} />)}
                </Steps>
              </Col>
              <Col className="col-6 text-black shadow p-3 mb-5 bg-body rounded h-50">
                <h4 className="fw-bold">Form Preparation Before Take Off</h4>

                <Formik
                  validationSchema={schema}
                  // onSubmit={(values) => handleFormSubmit(values)}
                  initialValues={{
                    origin: '',
                    destination: '',
                    pilot_name: '',
                    kml: '',
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} className="text-start">
                      <Form.Group className="mb-3" controlId="origin">
                        <Form.Label className="fw-semibold">
                          Origin
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <Stack gap={1}>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Origin"
                              value={values.origin}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.origin && errors.origin ? <p className="error-text text-danger">{errors.origin}</p> : null}
                          </Stack>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="destination">
                        <Form.Label className="fw-semibold">
                          Destination
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <Stack gap={1}>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Destination"
                              value={values.destination}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.destination && errors.destination ? <p className="error-text text-danger">{errors.destination}</p> : null}
                          </Stack>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="pilot_name">
                        <Form.Label className="fw-semibold">
                          Pilot Name
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <Stack gap={1}>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Name Remote Pilot"
                              value={values.pilot_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.pilot_name && errors.pilot_name ? <p className="error-text text-danger">{errors.pilot_name}</p> : null}
                          </Stack>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="kml">
                        <Form.Label className="fw-semibold">
                          KML
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <Stack gap={1}>
                            <Form.Control
                              required
                              type="file"
                              placeholder="(316) 555-0116"
                              value={values.kml}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              accept=".png,.jpg,.jpeg,.webp"
                            />
                            {touched.kml && errors.kml ? <p className="error-text text-danger">{errors.kml}</p> : null}
                          </Stack>
                        </InputGroup>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
                <div className="col-md-12 text-center">
                  <Button variant="primary" className="w-75">Submit</Button>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="option2" title="Landing">
            <Row className="mt-4 p-5 content">
              <Steps vertical style={styles}>
                {state?.dataLanding?.datas?.map((item) => <Steps.Item key={item._id} status={item?.isDone ? 'finish' : 'wait'} title={item.time ? item?.time : '-'} description={item?.value} />)}
              </Steps>
            </Row>
          </Tab>
        </Tabs>
      </Row>

    </DashboardLayoutUser>

  );
}

export default DetailMonitoringUser;
