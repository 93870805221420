/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
import React, { useEffect, useReducer } from 'react';
import {
  Row, Tab, Tabs,
} from 'react-bootstrap';
import DashboardLayoutAdmin from 'components/layout/DashboardLayoutAdmin';
import { useParams } from 'react-router-dom';
import LoadingPageAdmin from 'components/LoadingPageAdmin';
import { getCookie, setCookie } from 'utils/SetCookies';
import { INITIAL_STATE, MonitoringReducer } from 'reducer/Admin/MonitoringReducer';
import 'styles/Admin/DetailMonitoringAdmin.css';
import Steps from 'rsuite/Steps';
import 'rsuite/dist/rsuite.min.css';
import axios from 'axios';

function DetailMonitoringAdmin() {
  const { number } = useParams();

  const auth = { token: getCookie('token') };

  const [state, dispatch] = useReducer(MonitoringReducer, INITIAL_STATE);

  const fetchGetDetailMonitoring = () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    axios({
      method: 'get',
      url: `monitoring/fetch/${number}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        const dataBaru = res.data.data;
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'detailAdmin',
            data: dataBaru,
            isLoading: false,
          },
        });
        dataBaru?.monitorings.forEach((item) => {
          if (item.key.toLowerCase() === 'preparation') {
            dispatch({
              type: 'SET_DATA',
              payload: {
                name: 'dataPreparation',
                data: item,
                isLoading: false,
              },
            });
          } else if (item.key.toLowerCase() === 'landing') {
            dispatch({
              type: 'SET_DATA',
              payload: {
                name: 'dataLanding',
                data: item,
                isLoading: false,
              },
            });
          }
          return item;
        });
      }
    });
  };

  useEffect(() => {
    if (auth.token) {
      setCookie('navName', 'Monitoring');
      fetchGetDetailMonitoring();
    }
  }, []);

  const styles = {
    width: '200px',
    display: 'inline-table',
    verticalAlign: 'top',
  };

  if (state.isLoading) {
    return <LoadingPageAdmin />;
  }
  return (
    <DashboardLayoutAdmin>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/admin/transponder-list">Transponder List</a></li>
          <li className="breadcrumb-item"><a href="/admin/monitoring">Monitoring</a></li>
          <li className="breadcrumb-item active" aria-current="page">Detail Monitoring</li>
        </ol>
      </nav>

      <h1 className="fs-3 fw-bold m-0 mb-2">
        Monitoring #
        {number}
      </h1>

      <h2 className="fs-5 m-0 mb-5">
        Preparation before take off
      </h2>

      <Row className="w-100">
        <Tabs fill variant="pills" defaultActiveKey="option1">
          <Tab eventKey="option1" title="Preparation & Take Off">
            <Row className="w-100 m-0 mt-4 p-5 content">
              <Steps vertical style={styles}>
                {state?.dataPreparation?.datas?.map((item) => <Steps.Item key={item._id} status={item?.isDone ? 'finish' : 'wait'} title={item.time ? item?.time : '-'} description={item?.value} />)}
                {/* <div className="d-flex flex-column flex-wrap" style={{ height: '60vh' }}>
                  {state?.dataPreparation?.datas?.map((item) => <Steps.Item key={item._id} status={item?.isDone ? 'finish' : 'wait'} style={{ 'margin-right': '300px' }} title={item.time ? item?.time : '-'} description={item?.value} />)}
                </div> */}
              </Steps>
            </Row>
          </Tab>
          <Tab eventKey="option2" title="Landing">
            <Row className="mt-4 p-5 content">
              <Steps vertical style={styles}>
                {state?.dataLanding?.datas?.map((item) => <Steps.Item key={item._id} status={item?.isDone ? 'finish' : 'wait'} title={item.time ? item?.time : '-'} description={item?.value} />)}
                {/* <div className="d-flex flex-column flex-wrap" style={{ height: '60vh' }}>
                  {state?.dataPreparation?.datas?.map((item) => <Steps.Item key={item._id} status={item?.isDone ? 'finish' : 'wait'} style={{ 'margin-right': '300px' }} title={item.time ? item?.time : '-'} description={item?.value} />)}
                </div> */}
              </Steps>
            </Row>
          </Tab>
        </Tabs>
      </Row>

    </DashboardLayoutAdmin>

  );
}

export default DetailMonitoringAdmin;
