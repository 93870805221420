import { Formik } from 'formik';
import React, { useState, useReducer } from 'react';
import {
  Button, Form, InputGroup, Modal, Stack,
} from 'react-bootstrap';
import * as Yup from 'yup';
import { INITIAL_STATE, PilotListUserReducer } from 'reducer/User/PilotListUserReducer';
import { getCookie } from 'utils/SetCookies';
import axios from 'axios';

function EditPilotVerified() {
  const [state, dispatch] = useReducer(PilotListUserReducer, INITIAL_STATE);

  const auth = { token: getCookie('token') };

  const [modalShow, setModalShow] = useState(false);

  const [showModal, setShowModal] = useState({
    activeID: '', update: false,
  });

  const handleShow = (id, bool) => setShowModal({ ...showModal, update: bool, activeID: id });

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required'),
    nik: Yup.string().required('NIK is required'),
    // ktp: Yup.string().required('KTP is required'),
    // certificate: Yup.string().required('Certificate is required'),
  });

  const updateStatus = async () => {
    axios({
      method: 'put',
      url: `pilots/verify/${showModal.activeID}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        const dataBaru = state.dataAdmin.map((item) => {
          if (item.id === showModal.activeID) {
            return { ...item, status: 'verified' };
          }
          return item;
        });
        dispatch({
          type: 'UPDATE_DATA',
          payload: {
            name: 'dataUser',
            data: dataBaru,
          },
        });

        handleShow('update', '', false);
      }
    });
  };

  const handleFormSubmit = () => {
    setModalShow(false);
  };

  return (
    <>
      <Button type="button" className="btn btn-primary fw-bold d-flex align-items-center" onClick={() => setModalShow(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
        </svg>
      </Button>

      <Modal centered className="modal-lg" show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg closeButtonModal position-absolute me-3 end-0" onClick={() => setModalShow(false)} viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
          <Modal.Title className="fs-5 fw-bold w-100 text-center">Edit Pilot</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-4">

          <Formik
            validationSchema={schema}
            onSubmit={(values) => handleFormSubmit(values)}
            initialValues={{
              name: '',
              nik: '',
              phone: '',
              // ktp: '',
              // certificate: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit} className="text-start">
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label className="fw-semibold">
                    Full Name
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />
                      {touched.name && errors.name ? <p className="error-text text-danger">{errors.name}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="nik">
                  <Form.Label className="fw-semibold">
                    NIK
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="3508980998761298"
                        value={values.nik}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />
                      {touched.nik && errors.nik ? <p className="error-text text-danger">{errors.nik}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label className="fw-semibold">
                    Phone
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="(316) 555-0116"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.phone && errors.phone ? <p className="error-text text-danger">{errors.phone}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div className="container">
            <div className="col-md-12 text-center">
              <Button variant="primary" className="w-100" onClick={updateStatus}>Update</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditPilotVerified;
