import 'styles/Admin/DetailPostFlightReportAdmin.css';
// import { Link } from 'react-router-dom';
import {
  Button, Col, Row, Form, Card, InputGroup,
} from 'react-bootstrap';
import DashboardLayoutAdmin from 'components/layout/DashboardLayoutAdmin';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react';
import { INITIAL_STATE, PostFlightReportDetailReducer } from 'reducer/Admin/PostFlightReportDetailReducer';
import { getCookie, setCookie } from 'utils/SetCookies';
import LoadingPageAdmin from 'components/LoadingPageAdmin';
import { Formik } from 'formik';
import AlertReact from 'components/alert/AlertReact';

function DetailPostFlightReportAdmin() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, dispatch] = useReducer(PostFlightReportDetailReducer, INITIAL_STATE);
  const auth = { token: getCookie('token') };
  const [showAlert, setShowAlert] = useState(false);
  const [alertDetail, setAlertDetail] = useState({
    message: '', variant: '',
  });
  const fetchGetPFR = () => {
    axios({
      method: 'get',
      url: `post-flight-report/details/${id}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        const hasil = res.data.data;
        const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
        console.log();
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'id',
            // eslint-disable-next-line no-underscore-dangle
            data: hasil._id,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'operator_name',
            data: hasil.operator_name,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'pilot_name',
            data: hasil.pilot_name,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'email',
            data: hasil.email,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'other_pilot_name',
            data: hasil.other_pilot_name,
            isLoading: false,
          },
        });
        const FlightDate = hasil.flight_date;
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'flight_date',
            data: `${(`0${new Date(FlightDate).getDate()}`).slice(-2)} ${bulan[new Date(FlightDate).getMonth()]} ${new Date(FlightDate).getFullYear()}, ${new Date(FlightDate).getHours()}:${new Date(FlightDate).getMinutes()}`,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'total_flight_time',
            data: hasil.total_flight_time,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'nomer_perjanjian',
            data: hasil.nomer_perjanjian,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'notam_number',
            data: hasil.notam_number,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'deviation',
            data: hasil.deviation,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'deviation_description',
            data: hasil.deviation_description,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'status',
            data: hasil.status,
            isLoading: false,
          },
        });
        console.log(hasil.status);
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'damage_list',
            data: hasil.damage_list[0].datas,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'abnormal_condition_list',
            data: hasil.abnormal_condition_list[0].datas,
            isLoading: false,
          },
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'other_abnormal_condition',
            data: hasil.other_abnormal_condition,
            isLoading: false,
          },
        });
      }
    });
  };
  const PfrId = state.id;
  const fetchEditStatus = (val) => {
    axios({
      method: 'put',
      url: `post-flight-report/update-status/${PfrId}?status=${val}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
      data: {
        status: val,
      },
    }).then((res) => {
      if (res) {
        console.log(res);
        const messageAlert = res?.data?.message;
        setAlertDetail({ message: messageAlert, variant: 'success' });
        setShowAlert(true);
        const timeId = setTimeout(() => {
          setShowAlert(false);
          navigate(-1);
        }, 1500);
        return () => {
          clearTimeout(timeId);
        };
      }
      return null;
    }).catch((res) => {
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      const messageAlert = res?.data?.message;
      setAlertDetail({ message: messageAlert, variant: 'danger' });
      setShowAlert(true);
      const timeId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    });
  };
  const onUpdateField = (e) => {
    console.log(e.target.value);
    console.log(PfrId);
    fetchEditStatus(e.target.value);
  };
  useEffect(() => {
    if (auth.token) {
      setCookie('navName', 'Post Flight Report');
      fetchGetPFR();
    }
  }, []);
  const CurrStatus = state.status;
  function currentStatus() {
    if (CurrStatus === 'accepted') {
      return (
        <>
          <option value="accepted">Accepted</option>
          {/* <option value="waiting">Not Validated Yet</option> */}
        </>
      );
    }
    return (
      <>
        <option value="waiting">Not Validated Yet</option>
        <option value="accepted">Accepted</option>
      </>
    );
  }
  function checkStatus(item, index, tipe) {
    if (!item.isSelected) {
      return (
        <Form.Check
          disabled
          label={item.value}
          name={index}
          type="radio"
          id={tipe + id}
        />
      );
    }
    return (
      <Form.Check
        disabled
        label={item.value}
        name={index}
        type="radio"
        checked
        id={tipe + id}
      />
    );
  }
  if (state.isLoading) {
    return <LoadingPageAdmin />;
  }
  return (
    <DashboardLayoutAdmin>
      <Row className="position-relative">
        <div className="position-fixed end-0 me-8" style={{ width: 'inherit' }}>
          <AlertReact message={alertDetail.message} showAlert={showAlert} setShowAlert={setShowAlert} variant={alertDetail.variant} />
        </div>
      </Row>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/admin/transponder-list">Transponder</a></li>
          <li className="breadcrumb-item"><a href="/admin/post-flight-report">Post Flight Report</a></li>
          <li className="breadcrumb-item active" aria-current="page">Detail Post Flight Report</li>
        </ol>
      </nav>
      <Row className="mb-3">
        <Col className="col-auto me-auto">
          <h1 className="fs-2 fw-bold">
            Post Flight Report #
            {id}
          </h1>
          <p className="fs-5">Review the post flight report ensure the safety of people around you.</p>
        </Col>
        <Col className="col-3 ms-auto">
          <Formik
            initialValues={{
              status: '',
            }}
          >
            {({
              values,
            }) => (
              <Form noValidate>
                <Form.Group controlId="status">
                  <InputGroup>
                    <Form.Select
                      className="border border-2 border-dark border-opacity-25 mt-5"
                      onChange={onUpdateField}
                      valus={values.status}
                    >
                      {currentStatus()}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Card body>
        <Row>
          <h1 className="fs-4 fw-bold">Post Flight Report Form</h1>
          <Col className="col-5 me-auto">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold">Nama Operator</Form.Label>
                <Form.Control disabled className="mb-3" type="nama-operator" placeholder={state.operator_name} />
                <Form.Label className="fw-bold">Nama Remote Pilot</Form.Label>
                <Form.Control disabled className="mb-3" type="nama-remote-pilot" placeholder={state.pilot_name} />
                <Form.Label className="fw-bold">Nama Remote Pilot Lainnya</Form.Label>
                <Form.Control disabled className="mb-3" type="nama-remote-pilot-lainnya" placeholder={state.other_pilot_name} />
                <Form.Label className="fw-bold">Tanggal Terbang</Form.Label>
                <Form.Control disabled className="mb-3" type="tanggal-terbang" placeholder={state.flight_date} />
                <Form.Label className="fw-bold">Total Waktu Terbang (menit)</Form.Label>
                <Form.Control disabled className="mb-3" type="total-waktu-terbang" placeholder={state.total_flight_time} />
                <Form.Label className="fw-bold">Kejadian Kecelakaan / Insiden Serius</Form.Label>
                {state.damage_list ? state.damage_list.map((item, index) => (
                  checkStatus(item, index, 'kecelakaan')
                )) : (
                  <Form.Check
                    disabled
                    label="Tidak ada"
                    name="kecelakaan"
                    type="radio"
                    id="val0"
                  />
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicKecelakaan">
                <Form.Control disabled type="kecelakaan" placeholder={state.other_damage} />
              </Form.Group>
            </Form>
          </Col>
          <Col className="col-5 me-auto">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold">Email</Form.Label>
                <Form.Control disabled className="mb-3" type="email" placeholder={state.email} />
                <Form.Label className="fw-bold">Nomor Perijinan Pengoperasian</Form.Label>
                <Form.Control disabled className="mb-3" type="nama-perijinan-pengoperasion" placeholder={state.nomer_perjanjian} />
                <Form.Label className="fw-bold">Notam Number</Form.Label>
                <Form.Control disabled className="mb-3" type="notam-number" placeholder={state.notam_number} />
                <Form.Label className="fw-bold">Notam/History Log</Form.Label>
                <Button type="button" className="mb-3 btn btn-primary fw-semibold d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-download me-2" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                  <p className="m-0 p-0">Download File</p>
                </Button>
                <Form.Label className="fw-bold">Apakah penerbangan Anda menyimpang dari perijinan/persetujuan yang telah diberikan?</Form.Label>
                <Form.Control disabled className="mb-3" type="penyimpangan" placeholder={state.other_damage} />
                <Form.Label className="fw-bold">Deskripsi Penyimpangan</Form.Label>
                <Form.Control disabled className="mb-3" type="deskripsi-penyimpangan" placeholder={state.deviation_description} />
                <Form.Label className="fw-bold">Pengoperasian Pada Kondisi Tidak Normal</Form.Label>
                {state.abnormal_condition_list ? state.abnormal_condition_list.map((item, index) => (
                  checkStatus(item, index, 'operasi')
                )) : (
                  <Form.Check
                    disabled
                    label="Tidak ada"
                    name="operasi"
                    type="radio"
                    id="val0"
                  />
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control disabled type="operasi" placeholder={state.other_abnormal_condition} />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Card>
    </DashboardLayoutAdmin>
  );
}

export default DetailPostFlightReportAdmin;
