/* eslint-disable react/destructuring-assignment */
function ConvertISOtoDateField(string) {
  if (string) {
    const [YYYY, MM, DD] = string.split(/[/:\-T]/);

    return `${YYYY}-${MM}-${DD}`;
  }
  return null;
}

export default ConvertISOtoDateField;
