/* eslint-disable no-underscore-dangle */
import React, { useState, useReducer } from 'react';
import 'styles/User/PilotListUser.css';
import {
  Row, Col, Form, InputGroup, Stack, Button,
} from 'react-bootstrap';
import DashboardLayoutAdmin from 'components/layout/DashboardLayoutAdmin';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Dropdown from 'react-bootstrap/Dropdown';
import LoadingPageAdmin from 'components/LoadingPageAdmin';
import { INITIAL_STATE, ProfileAdmin } from 'reducer/Admin/ProfileAdmin';

function PilotListUser() {
  const schema = Yup.object().shape({

    // name: Yup.string().required('Full Name is required'),
    // email: Yup.string().required('Email Address is required'),
    // phone: Yup.string().required('Phone Number is required'),
    // company_name: Yup.string().required('Company Name is required'),
    // address: Yup.string().required('Address is required'),
    ktp: Yup.string().required('KTP is required'),
    akta: Yup.string().required('Akta Perusahaan is required'),
    // email_recovery: Yup.string().required('Email Recovery is required'),
    // password: Yup.string().required('Password is required'),
    // confirm_password: Yup.string().required('Confirm Password is required'),
    // business_category: Yup.string().required('Business Category is required'),
  });
  const [state] = useReducer(ProfileAdmin, INITIAL_STATE);
  const [modalShow, setModalShow] = useState(false);

  if (state.isLoading) {
    return <LoadingPageAdmin />;
  }

  return (
    <DashboardLayoutAdmin>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/user">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/user/profile">User Profile</a></li>
        </ol>
      </nav>
      <Row className="mb-3">
        <Col className="col-auto me-auto">
          <h1 className="fs-2 fw-bold">User Profile</h1>
          <p>Detail of user profile</p>
        </Col>
        <Col className="col-auto" show={modalShow} onHide={() => setModalShow(false)}>
          <Button variant="outline-primary" onClick={() => setModalShow(false)}>Cancel</Button>
        </Col>
        <Col className="col-auto pe-4" show={modalShow} onHide={() => setModalShow(false)}>
          <Button variant="primary" onClick={() => setModalShow(false)}>Update</Button>
        </Col>
      </Row>
      <div className="mb-5">
        <Row className="w-100 shadow">
          <Col className="col-6 text-black p-3 bg-body rounded h-50">
            <h4 className="fw-bold pb-2">User Data</h4>
            <Formik
              validationSchema={schema}
                  // onSubmit={(values) => handleFormSubmit(values)}
              initialValues={{
                name: '',
                email: '',
                company_name: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="text-start">

                  <Form.Group className="mb-3" controlId="company_name">
                    <Form.Label className="fw-semibold">
                      Company Name
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          type="text"
                          placeholder="Company Name"
                          value={values.company_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.company_name && errors.company_name ? <p className="error-text text-danger">{errors.company_name}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label className="fw-semibold">
                      E-mail Address
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          type="text"
                          placeholder="Email Address"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email ? <p className="error-text text-danger">{errors.email}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                        Category
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="text-center w-100">
                        <Dropdown.Item href="#/action-1">Fast Moving Consummer Goods</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Food & Beverage</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Government and Public Administration</Dropdown.Item>
                        <Dropdown.Item href="#/action-5">Hospitality & Tourism</Dropdown.Item>
                        <Dropdown.Item href="#/action-6">Housing & Apartment</Dropdown.Item>
                        <Dropdown.Item href="#/action-7">Retail & Manufacturer</Dropdown.Item>
                        <Dropdown.Item href="#/action-8">Transportation, Distribution, and Logistics</Dropdown.Item>
                        <Dropdown.Item href="#/action-7">Other</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
          <Col className="col-6 text-black p-3 bg-body rounded h-50">
            <h4 className="fw-bold pb-2 text-white">User Data</h4>
            <Formik
              validationSchema={schema}
                  // onSubmit={(values) => handleFormSubmit(values)}
              initialValues={{
                address: '',
                phone: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="text-start">

                  <Form.Group className="mb-3" controlId="phone">
                    <Form.Label className="fw-semibold">
                      Phone Number
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.phone && errors.phone ? <p className="error-text text-danger">{errors.phone}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3 h-25" controlId="address">
                    <Form.Label className="fw-semibold">
                      Address
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          type="text"
                          placeholder="Address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          as="textarea"
                          rows={5}
                        />
                        {touched.address && errors.address ? <p className="error-text text-danger">{errors.address}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
      <div>
        <Row className="w-100 shadow">
          <Col className="col-6 text-black p-3 mb-5 bg-body rounded h-50">
            <h4 className="fw-bold pb-2">User Privacy</h4>
            <Formik
              validationSchema={schema}
                  // onSubmit={(values) => handleFormSubmit(values)}
              initialValues={{
                email_recovery: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="text-start">

                  <Form.Group className="mb-3" controlId="email_recovery">
                    <Form.Label className="fw-semibold">
                      Recovery E-mail
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          type="text"
                          placeholder="e-mail"
                          value={values.email_recovery}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.email_recovery && errors.email_recovery ? <p className="error-text text-danger">{errors.email_recovery}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
          <Col className="col-6 text-black p-3 mb-5 bg-body rounded h-50">
            <h4 className="fw-bold pb-2 text-white">User Privacy</h4>
            <Formik
              validationSchema={schema}
                  // onSubmit={(values) => handleFormSubmit(values)}
              initialValues={{
                password: '',
                confirm_password: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="text-start">
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label className="fw-semibold">
                      Password
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          type="text"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.password && errors.password ? <p className="error-text text-danger">{errors.password}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="confirm_password">
                    <Form.Label className="fw-semibold">
                      Confirm Password
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          type="text"
                          placeholder="Confirm Password"
                          value={values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.confirm_password && errors.confirm_password ? <p className="error-text text-danger">{errors.confirm_password}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>

      </div>
    </DashboardLayoutAdmin>
  );
}

export default PilotListUser;
