import { Formik } from 'formik';
import React, { useReducer, useState } from 'react';
import {
  Button, Col, Container, Form, InputGroup, Row, Stack,
} from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { INITIAL_STATE, RegisterUserReducer } from 'reducer/User/RegisterUserReducer';
import AlertReact from 'components/alert/AlertReact';
import 'styles/User/RegisterUser.css';
import * as Yup from 'yup';

function RegisterUser() {
  const [state, dispatch] = useReducer(RegisterUserReducer, INITIAL_STATE);
  const [showAlert, setShowAlert] = useState(false);
  const [alertDetail, setAlertDetail] = useState({
    message: '', variant: '',
  });

  const handleShowPassword = () => dispatch({ type: 'SET_SHOW_PASSWORD' });
  const handleShowConfirmPassword = () => dispatch({ type: 'SET_SHOW_CONFIRM_PASSWORD' });

  const handleChangeCheckbox = (e) => {
    dispatch({
      type: 'SET_REMEMBER',
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const schemaCompany = Yup.object().shape({
    name: Yup.string().min(5, 'Must be 5 characters or more').required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    registered_as: Yup.string().required('Role is required'),
    company: Yup.string().when('registered_as', {
      is: (val) => val === 'company',
      then: Yup.string().required('Company Name is required'),
    }),
    password: Yup.string().min(8, 'Must be 8 characters or more').required('Password is required'),
    confirmPassword: Yup.string().min(8, 'Must be 8 characters or more').required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  // const schemaIndividual = Yup.object().shape({
  //   name: Yup.string().min(5, 'Must be 5 characters or more').required('Name is required'),
  //   email: Yup.string().email('Invalid email address').required('Email is required'),
  //   registered_as: Yup.string().required('registered_as is required'),
  //   company: Yup.string().required('Company Name is required'),
  //   password: Yup.string().min(8, 'Must be 8 characters or more').required('Password is required'),
  //   confirmPassword: Yup.string().min(8, 'Must be 8 characters or more').required('Confirm password is required')
  //     .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  // });

  // eslint-disable-next-line no-unused-vars
  const fetchRegisterUser = async (values) => {
    dispatch({ type: 'SET_IS_LOADING_SUBMIT', payload: true });
    let dataRegister;
    if (values.registered_at === 'company') {
      dataRegister = {
        name: values.name,
        email: values.email,
        registered_as: values.registered_as,
        company: values.company,
        password: values.password,
      };
    } else {
      dataRegister = {
        name: values.name,
        email: values.email,
        registered_as: values.registered_as,
        password: values.password,
      };
    }
    await axios({
      method: 'post',
      url: 'auth/register',
      data: dataRegister,
    }).then((res) => {
      if (res) {
        dispatch({ type: 'SET_IS_LOADING_SUBMIT', payload: false });
        const messageAlert = res?.data?.message;
        setAlertDetail({ message: messageAlert, variant: 'success' });
        setShowAlert(true);
        const timeId = setTimeout(() => {
          setShowAlert(false);
          // navigate(-1);
          // navigate(`/user/transponder-list/detail-drone/${imei}`);
        }, 1500);

        return () => {
          clearTimeout(timeId);
        };
      }
      return null;
    }).catch((res) => {
      dispatch({ type: 'SET_IS_LOADING_SUBMIT', payload: false });
      const messageAlert = res?.data?.message;
      setAlertDetail({ message: messageAlert, variant: 'danger' });
      setShowAlert(true);
      const timeId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    });
  };

  const handleFormSubmit = (values) => {
    fetchRegisterUser(values);
  };

  return (
    <Container fluid className="vh-100">
      <Row className="position-relative">
        <div className="position-fixed end-0 me-4" style={{ width: 'inherit' }}>
          <AlertReact message={alertDetail.message} showAlert={showAlert} setShowAlert={setShowAlert} variant={alertDetail.variant} />
        </div>
      </Row>
      <Row className="h-30">
        <Col className="bgLogin d-none d-lg-block" />
        <Col className="d-flex align-items-center justify-content-center mb-5">
          <Col xs={12} md={11} lg={8}>
            <div className="text-center mt-5">
              <h1 className="fw-bold">ITS PUTA Radar</h1>
              <p>Radar24 helps you monitor the drone position in real time</p>
            </div>
            <Formik
              validationSchema={schemaCompany}
              onSubmit={(values) => handleFormSubmit(values)}
              initialValues={{
                name: '',
                email: '',
                registered_as: '',
                company: '',
                password: '',
                confirmPassword: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="text-start">
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Full Name</Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="name"
                          placeholder="Enter Full Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.name && errors.name ? <p className="error-text text-danger">{errors.name}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>E-mail</Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email ? <p className="error-text text-danger">{errors.email}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="registered_as">
                    <Form.Label>Registered As</Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Select
                          required
                          value={values.registered_as}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option hidden>Select your role</option>
                          <option value="individu">Individual</option>
                          <option value="company">Company</option>
                        </Form.Select>
                        {touched.registered_as && errors.registered_as ? <p className="error-text text-danger">{errors.registered_as}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="company"
                  >
                    <Form.Label>Company Name</Form.Label>
                    <InputGroup hasValidation>
                      <Stack gap={1}>
                        <Form.Control
                          disabled={values.registered_as.toLowerCase() === 'individu'}
                          required
                          type="company"
                          placeholder="Enter Company Name"
                          value={values.company}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.company && errors.company ? <p className="error-text text-danger">{errors.company}</p> : null}
                      </Stack>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Stack gap={1}>
                      <InputGroup hasValidation className="position-relative d-flex align-items-center">
                        <Form.Control
                          required
                          type={state.showPassword ? 'text' : 'password'}
                          placeholder="Enter password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputGroup.Text className="bg-transparent position-absolute border-0 end-0">
                          {state.showPassword
                            ? (
                              <svg id="tooglePassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16" onClick={handleShowPassword}>
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                              </svg>
                            )
                            : (
                              <svg id="tooglePassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16" onClick={handleShowPassword}>
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                              </svg>
                            )}
                        </InputGroup.Text>
                      </InputGroup>
                      {touched.password && errors.password ? <p className="error-text text-danger">{errors.password}</p> : null}
                    </Stack>
                  </Form.Group>
                  <Form.Group className="m-0" controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Stack gap={1}>
                      <InputGroup hasValidation className="position-relative d-flex align-items-center">
                        <Form.Control
                          required
                          type={state.showConfirmPassword ? 'text' : 'password'}
                          placeholder="Re-enter password"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputGroup.Text className="bg-transparent position-absolute border-0 end-0">
                          {state.showConfirmPassword
                            ? (
                              <svg id="tooglePassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16" onClick={handleShowConfirmPassword}>
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                              </svg>
                            )
                            : (
                              <svg id="tooglePassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16" onClick={handleShowConfirmPassword}>
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                              </svg>
                            )}
                        </InputGroup.Text>
                      </InputGroup>
                      {touched.confirmPassword && errors.confirmPassword ? <p className="error-text text-danger">{errors.confirmPassword}</p> : null}
                    </Stack>
                  </Form.Group>

                  <Row className="mt-3 mb-3">
                    <Col>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" onChange={handleChangeCheckbox} />
                      </Form.Group>
                    </Col>
                    <Col className="text-end">
                      <p className="fw-bold text-danger">Forgot Password?</p>
                    </Col>
                  </Row>

                  <Button className="w-100 fw-semibold mb-4" type="submit" variant="primary" size="lg">
                    Sign Up
                  </Button>

                  <Row>
                    <Link to="/" className="text-center fw-bold">
                      Already have an account? Login
                    </Link>
                  </Row>
                </Form>

              )}
            </Formik>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterUser;
