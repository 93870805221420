import axios from 'axios';
import { Formik } from 'formik';
import React, { useState } from 'react';
import {
  Button, Form, InputGroup, Modal, Stack,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { getCookie } from 'utils/SetCookies';
import * as Yup from 'yup';

function AddTransponderBtn() {
  const navigate = useNavigate();
  const auth = { token: getCookie('token') };

  const [modalShow, setModalShow] = useState(false);

  const schema = Yup.object().shape({
    transponderIMEI: Yup.string().required('Transponder IMEI is required'),
    transponderName: Yup.string().required('Transponder name is required'),
    serialNumber: Yup.string().required('Serial number is required'),
  });

  const fetchAddTransponder = async (values) => {
    await axios({
      method: 'post',
      url: 'transponder/create',
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
      data: {
        imei: values.transponderIMEI,
        serial_number: values.serialNumber,
        name: values.transponderName,
        notes: values.description ? values.description : '-',
      },
    }).then((res) => {
      if (res) {
        navigate(0);
        setModalShow(false);
      }
    });
  };

  const handleFormSubmit = (values) => {
    fetchAddTransponder(values);
  };

  return (
    <>
      <Button type="button" className="btn btn-primary fw-bold d-flex align-items-center" onClick={() => setModalShow(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
        </svg>
        <p className="m-0 p-0">Add Transponder</p>
      </Button>

      <Modal centered className="modal-lg" show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg closeButtonModal position-absolute me-3 end-0" onClick={() => setModalShow(false)} viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
          <Modal.Title className="fs-5 fw-bold w-100 text-center">Add Transponder</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-4">

          <Formik
            validationSchema={schema}
            onSubmit={(values) => handleFormSubmit(values)}
            initialValues={{
              transponderName: '',
              transponderIMEI: '',
              serialNumber: '',
              description: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit} className="text-start">
                <Form.Group className="mb-3" controlId="transponderIMEI">
                  <Form.Label className="fw-semibold">
                    Transponder IMEI
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter transponder IMEI"
                        value={values.transponderIMEI}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.transponderIMEI && errors.transponderIMEI ? <p className="error-text text-danger">{errors.transponderIMEI}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="transponderName">
                  <Form.Label className="fw-semibold">
                    Transponder Name
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter transponder name"
                        value={values.transponderName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.transponderName && errors.transponderName ? <p className="error-text text-danger">{errors.transponderName}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="serialNumber">
                  <Form.Label className="fw-semibold">
                    Serial Number
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter serial number"
                        value={values.serialNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.serialNumber && errors.serialNumber ? <p className="error-text text-danger">{errors.serialNumber}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" controlId="description">
                  <Form.Label className="fw-semibold">Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter description"
                    value={values.description}
                    onChange={handleChange}
                    style={{ height: '100px' }}
                  />
                </Form.Group>

                <Button className="w-100" type="submit" variant="primary">
                  Add Transponder
                </Button>
              </Form>

            )}
          </Formik>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddTransponderBtn;
