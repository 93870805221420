export const INITIAL_STATE = {
  showPassword: false,
  rememberMe: false,
  isLoading: false,
};

export const LoginReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SHOW_PASSWORD':
      return {
        ...state,
        showPassword: !state.showPassword,
      };
    case 'SET_REMEMBER':
      return {
        ...state,
        rememberMe: !state.rememberMe,
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
