/* eslint-disable no-underscore-dangle */
import React, { useEffect, useReducer, useState } from 'react';
import 'styles/Admin/TransponderListAdmin.css';
import { Link } from 'react-router-dom';
import {
  Button, Col, Dropdown, Form, Row, Stack, Table,
} from 'react-bootstrap';
import AddTransponderBtn from 'components/buttonModal/AddTransponderBtn';
import DashboardLayoutAdmin from 'components/layout/DashboardLayoutAdmin';

import axios from 'axios';
import { getCookie, setCookie } from 'utils/SetCookies';
import LoadingPageAdmin from 'components/LoadingPageAdmin';
import { INITIAL_STATE, TransponderListAdminReducer } from 'reducer/Admin/TransponderListAdminReducer';
import PaginationControl from 'components/PaginationControl';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';

function TransponderListAdmin() {
  const [state, dispatch] = useReducer(TransponderListAdminReducer, INITIAL_STATE);
  const [pagination, setPagination] = useState({
    current_page: 1, entries: 10, pagination_items: [], multiple: 0,
  });

  const auth = { token: getCookie('token') };

  function checkStatus(status) {
    if (status.toLowerCase() === 'active') {
      return (<div className="status-active text-center px-3 py-2 rounded">Active</div>);
    }
    return (<div className="status-inactive text-center px-3 py-2 rounded">Inactive</div>);
  }

  function checkAvailability(isSold) {
    if (isSold) {
      return (<div className="status-rejected text-center px-3 py-2 rounded">Sold out</div>);
    }
    return (<div className="status-active text-center px-3 py-2 rounded">Available</div>);
  }

  const getDataPagination = async () => {
    axios({
      method: 'get',
      url: `transponder/pagination/${pagination.current_page}/${pagination.entries}`, // pagination/current_page/many
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        if (res.data.status !== 204) {
          const dataBaru = res.data.data.results;

          dispatch({
            type: 'UPDATE_DATA',
            payload: {
              name: 'dataAdmin',
              data: dataBaru,
            },
          });
        }
      }
    });
  };

  const fetchGetTransponderList = async () => {
    axios({
      method: 'get',
      url: `transponder/pagination/${pagination.current_page}/${pagination.entries}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then(async (res) => {
      if (res) {
        if (res.data.status !== 204) {
          console.log(res);
          const dataBaru = res.data.data.results;

          for (let number = 1; number <= res.data.data.total_pages; number += 1) {
            pagination.pagination_items.push(number);
          }

          dispatch({
            type: 'SET_DATA',
            payload: {
              name: 'dataAdmin',
              data: dataBaru,
            },
          });
        }
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      }
    });
  };

  useEffect(() => {
    getDataPagination();
  }, [pagination.current_page, pagination.entries]);

  useEffect(() => {
    if (auth.token) {
      dispatch({ type: 'SET_IS_LOADING', payload: true });
      setCookie('navName', 'Transponder List');
      fetchGetTransponderList();
    }
  }, []);

  console.log(state.dataAdmin);

  if (state.isLoading) {
    return <LoadingPageAdmin />;
  }
  return (
    <DashboardLayoutAdmin>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/admin/transponder-list">Transponder</a></li>
          <li className="breadcrumb-item active" aria-current="page">Transponder List</li>
        </ol>
      </nav>
      <Row className="mb-3">
        <Col className="col-auto me-auto"><h1 className="fs-3 fw-bold m-0">Transponder List</h1></Col>
        <Col className="col-auto">
          <AddTransponderBtn fetchGetTransponderList={fetchGetTransponderList} />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-auto me-auto">
          <p className="fs-5">
            Showing
            {' '}
            {pagination.multiple + 1}
            {' '}
            to
            {' '}
            {(pagination.multiple + parseInt(pagination.entries, 10) < pagination.pagination_items.length) ? (pagination.multiple + parseInt(pagination.entries, 10)) : pagination.pagination_items.length}
            {' '}
            of
            {' '}
            {pagination.entries}
            {' '}
            entries
          </p>
        </Col>
        <Col className="col-auto">
          <Stack direction="horizontal" gap={3}>
            <Dropdown onSelect={(e) => setPagination({
              ...pagination, current_page: 1, entries: e, multiple: 0,
            })}
            >
              <DropdownToggle variant="light" id="entries" className="fw-semibold d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>
                <p className="m-0 p-0">
                  {pagination.entries}
                  {' '}
                  Entries
                </p>
              </DropdownToggle>

              <DropdownMenu>
                <Dropdown.Item eventKey="10">10</Dropdown.Item>
                <Dropdown.Item eventKey="20">20</Dropdown.Item>
                <Dropdown.Item eventKey="30">30</Dropdown.Item>
                <Dropdown.Item eventKey="40">40</Dropdown.Item>
                <Dropdown.Item eventKey="50">50</Dropdown.Item>
              </DropdownMenu>
            </Dropdown>

            <Button type="button" className="btn btn-light fw-semibold d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-list-ul me-2" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
              <p className="m-0 p-0">Column</p>
            </Button>
            <Button type="button" className="btn btn-light fw-semibold d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-download me-2" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
              </svg>
              <p className="m-0 p-0">Export</p>
            </Button>
          </Stack>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-3 ms-auto">
          <Form.Control
            type="text"
            id="searchBar"
            placeholder="Search..."
          />
        </Col>
      </Row>

      <Table hover className="align-middle table-borderless">
        <thead>
          <tr className="text-center">
            <th className="leftCornerTableRadius">No</th>
            <th>Transponder IMEI</th>
            <th>Transponder Name</th>
            <th>Serial Number</th>
            <th>Description</th>
            <th>Status</th>
            <th className="rightCornerTableRadius">Availability</th>
          </tr>
        </thead>
        <tbody>
          {state.dataAdmin.length !== 0
            ? state?.dataAdmin?.map((item, index) => (
              <tr key={item._id}>
                <td className="text-center">{pagination.pagination_items[index + (pagination.multiple)]}</td>
                <td>
                  <Link
                    className="transponderIMEI fw-semibold"
                    to={`/admin/transponder-list/detail-drone/${item.imei}`}
                  >
                    {item.imei}
                  </Link>
                </td>
                <td>{item.name}</td>
                <td>{item.serial_number}</td>
                <td>{item.notes}</td>
                <td>
                  {checkStatus(item.status)}
                </td>
                <td>
                  {checkAvailability(item.isSold)}
                </td>
              </tr>
            ))
            : (
              <tr>
                <td colSpan="6" className="text-center">No data found</td>
              </tr>
            )}
        </tbody>
      </Table>

      <Row className="align-items-center justify-content-center">
        <Col className="col-auto">
          <PaginationControl itemsPerPage={pagination.entries} items={pagination.pagination_items} pagination={pagination} setPagination={setPagination} />
        </Col>
      </Row>

    </DashboardLayoutAdmin>
  );
}

export default TransponderListAdmin;
