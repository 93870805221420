import React from 'react';
import {
  Col, InputGroup, Row, Stack, Form,
} from 'react-bootstrap';

function UploadLicenseFile({
  handleBlur, setFieldValue, touched, errors, values,
}) {
  console.log(values);
  const fieldData = [
    {
      id: 'prosedurKedaruratan', name: 'Prosedur Kedaruratan', fileType: 'pdf', accept: '.pdf', required: true,
    },
    {
      id: 'fotoDrone', name: 'Foto Drone', fileType: 'jpeg, jpg, png', accept: 'image/jpeg, image/jpg, image/png', required: true,
    },
    {
      id: 'dokumenAsuransi', name: 'Dokumen Asuransi', fileType: 'pdf', accept: '.pdf', required: true,
    },
    {
      id: 'suratIzinDNP', name: 'Surat Izin DNP', fileType: 'pdf', accept: '.pdf', required: true,
    },
    {
      id: 'suratPermohonan', name: 'Surat Permohonan', fileType: 'pdf', accept: '.pdf', required: true,
    },
    {
      id: 'suratIzinMiliter', name: 'Surat Izin Militer', fileType: 'pdf', accept: '.pdf', required: true,
    },
    {
      id: 'daftarPeralatan', name: 'Daftar Peralatan', fileType: 'pdf', accept: '.pdf', required: true,
    },
    {
      id: 'suratIzinOtoritas', name: 'Surat Izin Otoritas', fileType: 'pdf', accept: '.pdf', required: true,
    },
  ];

  return (
    <>
      <h1 className="fs-5 text-black">Upload License File</h1>

      <Row>
        {fieldData.map((item) => (
          <Col className="col-6 text-black" key={item.id}>
            <Form.Group className="mb-3" controlId={item.id}>
              <Form.Label className="fw-semibold">
                {item.name}
                {item.required
                      && <span className="text-danger"> *</span>}
              </Form.Label>
              <Stack gap={1}>
                <InputGroup hasValidation>
                  <Form.Control
                    required
                    accept={item.accept}
                    type="file"
                    placeholder={`Enter ${item.name} field`}
                    value={undefined}
                    onChange={(e) => setFieldValue(item.id, e.currentTarget.files[0])}
                    onBlur={handleBlur}
                  />
                </InputGroup>
                {item.required
                  && touched[item.id] && errors[item.id] ? <p className="error-text text-danger">{errors[item.id]}</p> : (
                    <p className="text-muted">
                      Only can upload
                      {' '}
                      {item.fileType}
                      {' '}
                      file
                    </p>
                  )}
              </Stack>
            </Form.Group>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default UploadLicenseFile;
