/* eslint-disable no-underscore-dangle */
import React, { useEffect, useReducer, useState } from 'react';
import 'styles/Admin/AdminList.css';
import {
  Button, Col, Dropdown, Form, Row, Stack, Table,
} from 'react-bootstrap';
import AddAdminBtn from 'components/buttonModal/AddAdminBtn';
import DashboardLayoutAdmin from 'components/layout/DashboardLayoutAdmin';
import LoadingPageAdmin from 'components/LoadingPageAdmin';
// Dibawah ini untuk fetch data
import axios from 'axios';
import { getCookie, setCookie } from 'utils/SetCookies';
import { INITIAL_STATE, AdminListReducer } from 'reducer/Admin/AdminListReducer';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import PaginationControl from 'components/PaginationControl';
import DeleteData from 'components/buttonModal/DeleteData';

function AdminList() {
  // Fetch API
  const auth = { token: getCookie('token') };
  const [state, dispatch] = useReducer(AdminListReducer, INITIAL_STATE);
  const [pagination, setPagination] = useState({
    current_page: 1, entries: 10, pagination_items: [], multiple: 0,
  });

  const getDataPagination = async () => {
    axios({
      method: 'get',
      url: `users/filter/admin/pagination/${pagination.current_page}/${pagination.entries}`, // pagination/current_page/many
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        if (res.data.status !== 204) {
          const dataBaru = res.data.data.results;

          const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
          dispatch({
            type: 'SET_DATA',
            payload: {
              name: 'adminList',
              data: dataBaru.map((item) => ({
                _id: item._id,
                name: item.name,
                email: item.email,
                phone: item.phone,
                member: `${(`0${new Date(item.created_at).getDate()}`).slice(-2)} ${bulan[new Date(item.created_at).getMonth()]} ${new Date(item.created_at).getFullYear()}, ${new Date(item.created_at).getHours()}:${new Date(item.created_at).getMinutes()}`,
              })),
            },
          });
        }
      }
    });
  };

  const fetchAdminList = async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    axios({
      method: 'get',
      url: `users/filter/admin/pagination/${pagination.current_page}/${pagination.entries}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        if (res.data.status !== 204) {
          const dataBaru = res.data.data.results;

          for (let number = 1; number <= res.data.data.total_pages; number += 1) {
            pagination.pagination_items.push(number);
          }

          const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
          dispatch({
            type: 'SET_DATA',
            payload: {
              name: 'adminList',
              data: dataBaru.map((item) => ({
                _id: item._id,
                name: item.name,
                email: item.email,
                phone: item.phone,
                member: `${(`0${new Date(item.created_at).getDate()}`).slice(-2)} ${bulan[new Date(item.created_at).getMonth()]} ${new Date(item.created_at).getFullYear()}, ${new Date(item.created_at).getHours()}:${new Date(item.created_at).getMinutes()}`,
              })),
            },
          });
        }
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      }
    });
  };

  useEffect(() => {
    getDataPagination();
  }, [pagination.current_page, pagination.entries]);

  useEffect(() => {
    if (auth.token) {
      setCookie('navName', 'Admin List');
      fetchAdminList();
    }
  }, []);

  if (state.isLoading) {
    return <LoadingPageAdmin />;
  }

  return (
    <DashboardLayoutAdmin>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/admin/admin-list">Users</a></li>
          <li className="breadcrumb-item active" aria-current="page">Admin List</li>
        </ol>
      </nav>
      <Row className="mb-3">
        <Col className="col-auto me-auto"><h1 className="fs-2 fw-bold">Admin List</h1></Col>
        <Col className="col-auto">
          <AddAdminBtn />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-auto me-auto">
          <p className="fs-5">
            Showing
            {' '}
            {pagination.multiple + 1}
            {' '}
            to
            {' '}
            {(pagination.multiple + parseInt(pagination.entries, 10) < pagination.pagination_items.length) ? (pagination.multiple + parseInt(pagination.entries, 10)) : pagination.pagination_items.length}
            {' '}
            of
            {' '}
            {pagination.entries}
            {' '}
            entries
          </p>
        </Col>
        <Col className="col-auto">
          <Stack direction="horizontal" gap={3}>
            <Dropdown onSelect={(e) => setPagination({
              ...pagination, current_page: 1, entries: e, multiple: 0,
            })}
            >
              <DropdownToggle variant="light" id="entries" className="fw-semibold d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>
                <p className="m-0 p-0">
                  {pagination.entries}
                  {' '}
                  Entries
                </p>
              </DropdownToggle>

              <DropdownMenu>
                <Dropdown.Item eventKey="10">10</Dropdown.Item>
                <Dropdown.Item eventKey="20">20</Dropdown.Item>
                <Dropdown.Item eventKey="30">30</Dropdown.Item>
                <Dropdown.Item eventKey="40">40</Dropdown.Item>
                <Dropdown.Item eventKey="50">50</Dropdown.Item>
              </DropdownMenu>
            </Dropdown>
            <Button type="button" className="btn btn-light fw-semibold d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <g clipPath="url(#clip0_729_1975)">
                  <path d="M17.1667 3H4.66667C3.75 3 3 3.75 3 4.66667V16.3333C3 17.25 3.75 18 4.66667 18H17.1667C18.0833 18 18.8333 17.25 18.8333 16.3333V4.66667C18.8333 3.75 18.0833 3 17.1667 3ZM17.1667 4.66667V7.16667H4.66667V4.66667H17.1667ZM13 16.3333H8.83333V8.83333H13V16.3333ZM4.66667 8.83333H7.16667V16.3333H4.66667V8.83333ZM14.6667 16.3333V8.83333H17.1667V16.3333H14.6667Z" fill="#172D39" />
                </g>
                <defs>
                  <clipPath id="clip0_729_1975">
                    <rect width="20" height="20" fill="white" transform="translate(0.5 0.5)" />
                  </clipPath>
                </defs>
              </svg>
              <p className="m-0 p-1">Column</p>
            </Button>
            <Button type="button" className="btn btn-light fw-semibold d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                <path d="M15.1167 1.85833L13.9583 0.458333C13.7333 0.175 13.3917 0 13 0H3C2.60833 0 2.26667 0.175 2.03333 0.458333L0.883333 1.85833C0.641667 2.14167 0.5 2.51667 0.5 2.91667V13.3333C0.5 14.25 1.25 15 2.16667 15H13.8333C14.75 15 15.5 14.25 15.5 13.3333V2.91667C15.5 2.51667 15.3583 2.14167 15.1167 1.85833ZM3.2 1.66667H12.8L13.475 2.475H2.53333L3.2 1.66667ZM2.16667 13.3333V4.16667H13.8333V13.3333H2.16667ZM9.20833 5.83333H6.79167V8.33333H4.66667L8 11.6667L11.3333 8.33333H9.20833V5.83333Z" fill="#172D39" />
              </svg>
              <p className="m-0 p-1">Export</p>
            </Button>
          </Stack>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-3 ms-auto">
          <Form.Control
            type="text"
            id="searchBar"
            placeholder="Search..."
          />
        </Col>
      </Row>

      <Table hover className="align-middle table-borderless">
        <thead>
          <tr className="text-center">
            <th className="leftCornerTableRadius">No</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Member Since</th>
            <th className="rightCornerTableRadius">Action</th>
          </tr>
        </thead>
        <tbody>
          {state.adminList.length !== 0 ? state.adminList.map((item, index) => (
            <tr key={item._id} className="text-center">
              <td className="text-center">{pagination.pagination_items[index + (pagination.multiple)]}</td>
              <td>
                {item.name}
              </td>
              <td>{item.email}</td>
              <td>{item.phone}</td>
              <td>{item.member}</td>
              <td>
                <div>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_218_3567)">
                      <path d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_218_3567">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg> */}
                  <DeleteData />
                </div>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="6" className="text-center">No data found</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Row className="align-items-center justify-content-center">
        <Col className="col-auto">
          <PaginationControl itemsPerPage={pagination.entries} items={pagination.pagination_items} pagination={pagination} setPagination={setPagination} />
        </Col>
      </Row>

    </DashboardLayoutAdmin>
  );
}

export default AdminList;
