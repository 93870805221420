import React from 'react';
import {
  MapContainer, ScaleControl,
} from 'react-leaflet';
import { BasemapLayer } from 'react-esri-leaflet';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import { useParams } from 'react-router';
import MarkerHistoryTrack from 'components/Map/MarkerHistoryTrack';

function FullscreenMaps() {
  const { latitude, longitude } = useParams();

  return (
    <div className="vh-100 w-100 bg-success">
      <MapContainer className="containerMap vh-100" center={[latitude, longitude]} zoom={18} scrollWheelZoom fullscreenControl>
        <BasemapLayer name="ImageryLabels" />
        <BasemapLayer name="Imagery" />
        <ScaleControl />

        <MarkerHistoryTrack data={{ latitude, longitude }} />
      </MapContainer>
    </div>
  );
}

export default FullscreenMaps;
