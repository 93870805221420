import React, { useEffect, useReducer } from 'react';
import {
  Col, Row, Stack,
} from 'react-bootstrap';
import DashboardLayoutUser from 'components/layout/DashboardLayoutUser';
import { useParams } from 'react-router-dom';
import LoadingPageUser from 'components/LoadingPageUser';
import { getCookie, setCookie } from 'utils/SetCookies';
import { INITIAL_STATE, TransponderListUserReducer } from 'reducer/User/TransponderListUserReducer';
import axios from 'axios';
import Rockblock from 'assets/detailDrone/rockblock.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function DetailDroneUser() {
  const { imei } = useParams();

  const [state, dispatch] = useReducer(TransponderListUserReducer, INITIAL_STATE);

  const auth = { token: getCookie('token') };
  const droneSpecs = [
    { title: 'Max. take off weight', desc: state?.detailUser?.drone.max_take_weight },
    { title: 'Weight without payload', desc: state?.detailUser?.drone.weight_without_payload },
    { title: 'Maximum flight range', desc: state?.detailUser?.drone.max_flight_range },
    { title: 'Cruise speed', desc: state?.detailUser?.drone.cruise_speed },
    { title: 'Maximum cruise height', desc: state?.detailUser?.drone.max_cruise_height },
    { title: 'Operational payload height', desc: state?.detailUser?.drone.operational_payload_height },
    { title: 'Operational payload weight', desc: state?.detailUser?.drone.operational_payload_weight },
    { title: 'Fuselage material', desc: state?.detailUser?.drone.fuselage_material },
    { title: 'Wing material', desc: state?.detailUser?.drone.wing_material },
    { title: 'Proximity sensors', desc: state?.detailUser?.drone.proximity_sensors },
    { title: 'Precision landing mechanism', desc: state?.detailUser?.drone.precision_landing_mechanism },
    { title: 'File save system ', desc: state?.detailUser?.drone.file_save_system },
    { title: 'Operation system ', desc: state?.detailUser?.drone.operation_system },
    { title: 'Control system', desc: state?.detailUser?.drone.control_system },
    { title: 'Communication system', desc: state?.detailUser?.drone.communication_system },
  ];

  const fetchGetDetailDrone = () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    axios({
      method: 'get',
      url: `transponder/details/${imei}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'detailUser',
            data: res.data.data,
            isLoading: false,
          },
        });
      }
    });
  };

  useEffect(() => {
    if (auth.token) {
      setCookie('navName', 'Detail Drone');
      fetchGetDetailDrone();
    }
  }, []);

  if (state.isLoading) {
    return <LoadingPageUser />;
  }
  return (
    <DashboardLayoutUser>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/user">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/user/transponder-list">Transponder List</a></li>
          <li className="breadcrumb-item active" aria-current="page">Detail Drone</li>
        </ol>
      </nav>

      <Row className="mb-1 align-items-center">
        <Col className="col-auto me-auto"><h1 className="fs-3 fw-bold m-0">{state?.detailUser?.transponder?.name}</h1></Col>
        <Col className="col-auto">
          <Stack direction="horizontal" gap={3}>
            <div className="rounded-circle text-white p-3 d-flex align-items-center" style={{ backgroundColor: '#C90B1B' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              </svg>
            </div>
            <div className="rounded-circle text-white p-3 d-flex align-items-center" style={{ backgroundColor: '#0F4187' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chat-left-fill" viewBox="0 0 16 16">
                <path d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
              </svg>
            </div>
          </Stack>
        </Col>
      </Row>

      <h2 className="fs-4 m-0 mb-3">Detail drone</h2>

      <Row>
        <Col className="col-xl-6 col-xxl-4 mb-3">
          <LazyLoadImage
            className="w-100 rounded"
            alt="Drone Image"
            effect="blur"
            src={state?.detailUser?.drone?.drone_image ? state?.detailUser?.drone?.drone_image : Rockblock}
          />
          <p className="mt-3">{state?.detailUser?.transponder.notes}</p>
        </Col>
        <Col className="col-auto col-xl-6">
          <h3 className="fs-5 fw-bold m-0">Drone Specs</h3>
          <p className="fw-bold m-0 mb-3" style={{ color: '#666666' }}>
            {imei}
            {' '}
            -
            {' '}
            {state?.detailUser?.transponder.serial_number}
          </p>

          <Row>
            {droneSpecs.map((item) => (
              <Row className="mb-2" key={item.title}>
                <Col><p className="m-0">{item.title}</p></Col>
                <Col className={item.desc ? 'text-end' : 'text-start'}><p className="m-0">{item.desc ? item.desc : ': -'}</p></Col>
              </Row>
            ))}
          </Row>
        </Col>
      </Row>

    </DashboardLayoutUser>

  );
}

export default DetailDroneUser;
