import React, { useState } from 'react';
import {
  Button, Modal, Spinner,
} from 'react-bootstrap';
import { getCookie } from 'utils/SetCookies';
import axios from 'axios';
import { useNavigate } from 'react-router';

function DeleteData({ id, setAlertDetail, setShowAlert }) {
  const auth = { token: getCookie('token') };

  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const fetchDeletePilot = async () => {
    setIsLoadingDelete(true);
    await axios({
      method: 'delete',
      url: `pilots/delete/${id}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        console.log(res);
        const messageAlert = res?.data?.message;
        setAlertDetail({ message: messageAlert, variant: 'success' });
        setShowAlert(true);
        const timeId = setTimeout(() => {
          setIsLoadingDelete(false);
          setShowAlert(false);
          navigate(0);
        }, 1500);

        return () => {
          clearTimeout(timeId);
        };
      }
      return null;
    }).catch((res) => {
      const messageAlert = res?.data?.message;
      setAlertDetail({ message: messageAlert, variant: 'danger' });
      setShowAlert(true);
      const timeId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    });
  };

  return (
    <>
      <Button type="button" variant="danger" onClick={() => setModalShow(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_218_3567)">
            <path d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_218_3567">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </Button>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center">
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you really want to delete this data? This process cannot be undone</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setModalShow(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => fetchDeletePilot()}>
            {isLoadingDelete ? (
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )
              : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteData;
