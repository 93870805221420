export const INITIAL_STATE = {
  isLoading: false,
  takeoff: 0,
  landing: 0,
  durasi: 0,
  avg_hours: 0,
  max_hours: 0,
  min_hours: 0,
  nama_transponder: '-',
  imei: '-',
  serial: '-',
  validasi: '-',
  deskripsi: '-',
  nama_pilot: '-',
  departure: '-',
  arrival: '-',
  status: '-',
  ketersediaan: false,
  labelChart: [],
  groundspeed: [],
  detailControl: [],
};

export const HistoryDetailReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_DATA':
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    default:
      return state;
  }
};
