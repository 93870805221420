/* eslint-disable no-underscore-dangle */
import { Formik } from 'formik';
import React, { useState } from 'react';
import {
  Button, Form, InputGroup, Modal, Stack, Spinner,
} from 'react-bootstrap';
import { getCookie } from 'utils/SetCookies';
import axios from 'axios';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';

function AddPilotList({ setAlertDetail, setShowAlert }) {
  const auth = { token: getCookie('token') };

  const navigate = useNavigate();

  // const [showAlert, setShowAlert] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const [isLoadingAddPilot, setIsLoadingAddPilot] = useState(false);

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required'),
    nik: Yup.string().required('NIK is required'),
    ktp: Yup.mixed().required('KTP/Passport is required').test('fileSize', 'File too large', (value) => value && value.size <= (5000000)),
    certificate: Yup.mixed().required('Certificate is required').test('fileSize', 'File too large', (value) => value && value.size <= (5000000)),
    // expiration_date: Yup.string().required('Certificate Expiration Date is required'),
  });

  const fetchAddPilotList = async (values) => {
    setIsLoadingAddPilot(true);
    const form = new FormData();
    form.append('name', values.name);
    form.append('nik', values.nik);
    form.append('phone', values.phone);
    form.append('ktp', values.ktp);
    form.append('certificate', values.certificate);
    console.log(form);

    await axios({
      method: 'post',
      url: 'pilots',
      headers: {
        authorization: `Bearer ${auth.token}`,
        'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
      },
      data: form,
    }).then((res) => {
      if (res) {
        console.log(res);
        const messageAlert = res?.data?.message;
        setAlertDetail({ message: messageAlert, variant: 'success' });
        setShowAlert(true);
        const timeId = setTimeout(() => {
          setIsLoadingAddPilot(false);
          setShowAlert(false);
          navigate(0);
        }, 1500);

        return () => {
          clearTimeout(timeId);
        };
      }
      return null;
    }).catch((res) => {
      const messageAlert = res?.data?.message;
      setAlertDetail({ message: messageAlert, variant: 'danger' });
      setShowAlert(true);
      const timeId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    });
  };

  const handleFormSubmit = (values) => {
    console.log('masuk');
    console.log(values);
    fetchAddPilotList(values);
  };

  return (
    <>
      <Button type="button" className="btn btn-primary fw-bold d-flex align-items-center" onClick={() => setModalShow(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
        </svg>
        <p className="m-0 p-0">Add Pilot</p>
      </Button>

      <Modal centered className="modal-lg" show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg closeButtonModal position-absolute me-3 end-0" onClick={() => setModalShow(false)} viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
          <Modal.Title className="fs-5 fw-bold w-100 text-center">Add Pilot</Modal.Title>
        </Modal.Header>

        <Modal.Body className="px-5 py-4">

          {/* <Row className="position-relative">
            <div className="position-fixed end-0 me-4" style={{ width: 'inherit' }}>
              <AlertReact message={alertDetail.message} showAlert={showAlert} setShowAlert={setShowAlert} variant={alertDetail.variant} />
            </div>
          </Row> */}
          <Formik
            validationSchema={schema}
            onSubmit={(values) => handleFormSubmit(values)}
            initialValues={{
              name: '',
              nik: '',
              phone: '',
              ktp: undefined,
              certificate: undefined,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit} className="text-start">
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label className="fw-semibold">
                    Full Name
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Full Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.name && errors.name ? <p className="error-text text-danger">{errors.name}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="nik">
                  <Form.Label className="fw-semibold">
                    NIK
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="3508980998761298"
                        value={values.nik}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.nik && errors.nik ? <p className="error-text text-danger">{errors.nik}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label className="fw-semibold">
                    Phone
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="(316) 555-0116"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.phone && errors.phone ? <p className="error-text text-danger">{errors.phone}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="ktp">
                  <Form.Label className="fw-semibold">
                    Upload KTP/Passport
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="file"
                        placeholder="KTP/Passport"
                        value={undefined}
                        onChange={(event) => {
                          setFieldValue('ktp', event.currentTarget.files[0]);
                        }}
                        // onChange={(e) => setFile(e.target.files[0])}
                        onBlur={handleBlur}
                        accept=".png,.jpg,.jpeg,.webp"
                      />
                      {touched.ktp && errors.ktp ? <p className="error-text text-danger">{errors.ktp}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="certificate">
                  <Form.Label className="fw-semibold">
                    Upload Certificate
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="file"
                        placeholder="(316) 555-0116"
                        value={undefined}
                        onChange={(event) => {
                          setFieldValue('certificate', event.currentTarget.files[0]);
                        }}
                        onBlur={handleBlur}
                        accept=".pdf"
                      />
                      {touched.certificate && errors.certificate ? <p className="error-text text-danger">{errors.certificate}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group>

                {/* <Form.Group className="mb-3" controlId="expiration_date">
                  <Form.Label className="fw-semibold">
                    Certificate Expiration Date
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Stack gap={1}>
                      <Form.Control
                        required
                        type="date"
                        name="duedate"
                        placeholder="Due date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                      {touched.expiration_date && errors.expiration_date ? <p className="error-text text-danger">{errors.expiration_date}</p> : null}
                    </Stack>
                  </InputGroup>
                </Form.Group> */}
                <div className="container">
                  <div className="col-md-12 text-center">
                    <Button variant="outline-primary" className="m-3 w-25" onClick={() => navigate(-1)}>Add More Pilot</Button>
                    <Button variant="primary" className="w-25" type="submit">
                      {isLoadingAddPilot ? (
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      )
                        : 'Submit'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddPilotList;
