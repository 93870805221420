import React from 'react';
import { Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import safeDrone from 'assets/map/droneIcon/safe-drone.png';
import warningDrone from 'assets/map/droneIcon/warning-drone.png';
import dangerDrone from 'assets/map/droneIcon/danger-drone.png';

function MarkerComp({
  data, handleShow, index, status,
}) {
  const map = useMap();

  const statusIcon = () => {
    if (status === 'safe') return safeDrone;
    if (status === 'warning') return warningDrone;
    if (status === 'danger') return dangerDrone;
    return safeDrone;
  };
  const drone = new L.Icon({
    iconUrl: statusIcon(),
    iconSize: [40, 40],
  });
  return (
    <Marker
      position={[data?.latitude, data?.longitude]}
      icon={drone}
      eventHandlers={{
        click: (e) => { handleShow(index); map.flyTo([e.latlng.lat, e.latlng.lng], 18); },
      }}
    />
  );
}

export default MarkerComp;
