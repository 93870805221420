export const INITIAL_STATE = {
  isLoading: true,
  isLoadingSubmit: false,
  dataUser: [],
  detailDrone: {},
  droneDetailForm: {
    droneName: '',
    serialNumber: '',
    weightWithoutPayload: '',
    cruiseSpeed: '',
    maxRateOfClimb: '',
    volumetricPayload: '',
    wingMaterial: '',
    fuselageMaterial: '',
    fileSaveSystem: '',
    controlSystem: '',
    maxTakeOffWeight: '',
    maxFlightRange: '',
    maxSpeed: '',
    maxCruiseHeight: '',
    operationalPayloadWeight: '',
    proximitySensors: '',
    precisionLoading: '',
    operationSystem: '',
    communicationSystem: '',
    description: '',
  },
  licenseFiles: {
    prosedurKedaruratan: '',
    dokumenAsuransi: '',
    suratPermohonan: '',
    daftarPeralatan: '',
    fotoDrone: '',
    suratIzinDNP: '',
    suratIzinMiliter: '',
    suratIzinOtoritas: '',
  },
};

export const TransponderListUserReducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_IS_LOADING_SUBMIT':
      return {
        ...state,
        isLoadingSubmit: action.payload,
      };
    case 'SET_DATA':
      return {
        ...state,
        [action.payload.name]: action.payload.data,
        isLoading: action.payload.isLoading,
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    case 'SET_FORM':
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.idData]: action.payload.data,
        },
      };
    default:
      return state;
  }
};
