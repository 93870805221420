export const INITIAL_STATE = {
  isLoading: true,
  dataLivePosition: [],
  dataLayerKML: [],
  dataCard: [],
  dataShowPopup: [],
  labelsOverview: [],
  datasetsOverview: [],
  isClick: false,
};

export const DashboardAdminReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_DATA':
      return {
        ...state,
        [action.payload.name]: action.payload.data,
        isLoading: action.payload.isLoading,
      };
    case 'SET_CHART_DATA':
      return {
        ...state,
        labelsOverview: [...state.labelsOverview, action.payload.label],
        datasetsOverview: [...state.datasetsOverview, action.payload.data],
        isLoading: action.payload.isLoading,
      };
    case 'SET_SHOW_POPUP':
      return {
        ...state,
        dataShowPopup: action.payload.data,
        isClick: action.payload.isClick,
      };
    default:
      return state;
  }
};
