import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function ShowImageFileBtn({
  name, image, title,
}) {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <Button variant="outline-primary" type="button" className="w-75" onClick={handleShowModal}>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
          </svg>
          <span className="ms-2">Show file</span>
        </div>
      </Button>

      <Modal name={name} show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100">
            <LazyLoadImage
              className="modalImage"
              alt={title}
              effect="blur"
              src={image}
              width="100%"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShowImageFileBtn;
