import DashboardLayoutUser from 'components/layout/DashboardLayoutUser';
import React, { useEffect, useReducer } from 'react';
import {
  Button, Col, Form, Image, InputGroup, Row, Stack,
} from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'styles/User/DashboardLayoutUser.css';
import totalTransponderIcon from 'assets/dashboard/totalTransponderIcon.svg';
import totalTransponderAktifIcon from 'assets/dashboard/totalTransponderAktifIcon.svg';

import {
  LayerGroup,
  LayersControl,
  MapContainer, Polygon, Polyline, ScaleControl,
} from 'react-leaflet';
import { BasemapLayer } from 'react-esri-leaflet';
import axios from 'axios';
import { getCookie, setCookie } from 'utils/SetCookies';
import MarkerComp from 'components/Map/MarkerComp';
import { INITIAL_STATE, DashboardUserReducer } from 'reducer/User/DashboardAdminReducer';
import LoadingPageUser from 'components/LoadingPageUser';
import Rockblock from 'assets/detailDrone/rockblock.svg';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

// import dataLivePosition from 'data/User/dataLivePosition';
// import { data } from './Grafik';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function DashboardUser() {
  const auth = { token: getCookie('token') };

  const [state, dispatch] = useReducer(DashboardUserReducer, INITIAL_STATE);

  const optionsChart = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };
  const chartData = {
    labels: state.labelsOverview,
    datasets: [
      {
        data: state.datasetsOverview,
        backgroundColor: '#52CDFF',
      },
    ],
  };

  const getLayerKML = async () => {
    axios({
      method: 'get',
      url: 'dashboard/layers',
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        if (res.data.status !== 204) {
          const dataBaru = res?.data?.data.map((item) => item.coordinates);

          dispatch({
            type: 'SET_DATA',
            payload: {
              name: 'dataLayerKML',
              data: dataBaru,
            },
          });
        }
      }
    });
  };

  const fetchGetLivePosition = async () => {
    await axios({
      method: 'get',
      url: 'transponder/fetch/live-transponder',
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        console.log(res);
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'dataLivePosition',
            data: res.data.data,
            isLoading: false,
          },
        });
      }
    });
  };

  const fetchGetCardData = async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    await axios({
      method: 'get',
      url: 'dashboard/card',
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        dispatch({
          type: 'SET_DATA',
          payload: {
            name: 'dataCard',
            data: res.data.data,
            isLoading: false,
          },
        });
      }
    });
  };

  const fetchGetOverviewData = async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    axios({
      method: 'get',
      url: 'dashboard/overview',
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        const dataBaru = res.data.data;
        dataBaru.forEach((item) => {
          dispatch({
            type: 'SET_CHART_DATA',
            payload: {
              label: item.x,
              data: item.y,
            },
          });
        });
      }
    });
  };

  useEffect(() => {
    if (auth.token) {
      dispatch({ type: 'SET_IS_LOADING', payload: true });
      setCookie('navName', 'Dashboard');
      getLayerKML();
      fetchGetLivePosition();
      fetchGetCardData();
      fetchGetOverviewData();
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => { // assign interval to a variable to clear it.
      fetchGetLivePosition();
    }, 5000);

    return () => clearInterval(intervalId); // This is important
  });

  const handleShow = (index) => {
    const dataBaru = state?.dataLivePosition.map(() => ({ showAlert: false, showPopup: false }));
    dataBaru[index] = { showAlert: true, showPopup: true };
    dispatch({ type: 'SET_SHOW_POPUP', payload: { data: dataBaru, isClick: true } });
  };

  const handleShowPopup = (index) => {
    const dataBaru = [...state.dataShowPopup];
    dataBaru[index].showPopup = !state.dataShowPopup[index].showPopup;
    if (dataBaru[index].showAlert || dataBaru[index].showPopup) {
      dispatch({ type: 'SET_SHOW_POPUP', payload: { data: dataBaru, isClick: true } });
    } else {
      dispatch({ type: 'SET_SHOW_POPUP', payload: { data: dataBaru, isClick: false } });
    }
  };

  const handleShowAlert = (index) => {
    const dataBaru = [...state.dataShowPopup];
    dataBaru[index].showAlert = !state.dataShowPopup[index].showAlert;
    if (dataBaru[index].showAlert || dataBaru[index].showPopup) {
      dispatch({ type: 'SET_SHOW_POPUP', payload: { data: dataBaru, isClick: true } });
    } else {
      dispatch({ type: 'SET_SHOW_POPUP', payload: { data: dataBaru, isClick: false } });
    }
  };

  const rumusPresentase = (awal, akhir) => {
    const result = Math.round(((Math.abs(awal - akhir)) / akhir) * 100);
    return Number.isFinite(result) ? result : 100;
  };

  const statusColor = (status) => {
    if (status === 'safe') return '#29C56F';
    if (status === 'warning') return '#F7C102';
    if (status === 'danger') return '#D93300';
    return '#29C56F';
  };

  if (state.isLoading) {
    return <LoadingPageUser />;
  }
  return (
    <DashboardLayoutUser>
      <Row className="justify-content-between mb-5">
        <Col className="px-0 mx-2">
          <div className="px-4 py-5 rounded-3 d-flex align-items-center" style={{ boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px' }}>
            <div className="me-3">
              <Button variant="solid" className="p-2" style={{ backgroundColor: '#D7E9FF' }}>
                <Image src={totalTransponderIcon} />
              </Button>
            </div>
            <div>
              <p className="fs-4 fw-bold m-0">Total Transponder</p>
              <p className="m-0">
                <span className="fs-4 fw-bold me-2">{state?.dataCard?.transponder?.total}</span>
                Transponder
              </p>
              <div className="d-flex align-items-center fw-bold fs-5">
                <div className="d-flex align-items-center me-3" style={state?.dataCard?.transponder?.icon.toLowerCase() === 'up' ? { color: '#1DBD65' } : { color: '#C90B1B' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-up-short" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
                  </svg>
                  <p className="m-0">
                    {state?.dataCard?.transponder?.total || state?.dataCard?.transponder?.total_last_month
                      ? rumusPresentase(state.dataCard.transponder.total_last_month, state.dataCard.transponder.total) : 0}
                    %
                  </p>
                </div>
                <p className="m-0">{state?.dataCard?.transponder?.total_last_month}</p>
              </div>
            </div>
          </div>
        </Col>
        <Col className="px-0 mx-2">
          <div className="px-4 py-5 rounded-3 d-flex align-items-center" style={{ boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px' }}>
            <div className="me-3">
              <Button variant="solid" className="p-2" style={{ backgroundColor: '#DFFFDC' }}>
                <Image src={totalTransponderAktifIcon} />
              </Button>
            </div>
            <div>
              <p className="fs-4 fw-bold m-0">Total Transponder Aktif</p>
              <p className="m-0">
                <span className="fs-4 fw-bold me-2">{state?.dataCard?.transponder_active?.total}</span>
                Transponder
              </p>
              <div className="d-flex align-items-center fw-bold fs-5">
                <div className="d-flex align-items-center me-3" style={state?.dataCard?.transponder_active?.icon.toLowerCase() === 'up' ? { color: '#1DBD65' } : { color: '#C90B1B' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
                  </svg>
                  <p className="m-0">
                    {state?.dataCard?.transponder_active?.total || state?.dataCard?.transponder_active?.total_last_month
                      ? rumusPresentase(state.dataCard.transponder_active.total_last_month, state.dataCard.transponder_active.total) : 0}
                    %
                  </p>
                </div>
                <p className="m-0">{state?.dataCard?.transponder_active?.total_last_month}</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mb-5">
        <Form className="row m-0 p-0">
          <Col>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-bold">Latitude</Form.Label>
              <InputGroup>
                <Form.Control placeholder="Range 1" />
                <InputGroup.Text>-</InputGroup.Text>
                <Form.Control placeholder="Range 2" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-bold">Longitude</Form.Label>
              <InputGroup>
                <Form.Control placeholder="Range 1" />
                <InputGroup.Text>-</InputGroup.Text>
                <Form.Control placeholder="Range 2" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-bold">Altitude</Form.Label>
              <InputGroup>
                <Form.Control placeholder="Range 1" />
                <InputGroup.Text>-</InputGroup.Text>
                <Form.Control placeholder="Range 2" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col className="col-auto align-self-end">
            <Button type="button" className="btn btn-info fw-bold px-4">
              Apply
            </Button>
          </Col>
        </Form>
      </Row>

      <div className="livePositionWrapper mb-5">
        <Row className="mb-3">
          <Col><h2 className="fs-3 fw-bold">Live Position Transponder</h2></Col>
          <Col className="col-3">
            <Form>
              <Form.Control type="text" placeholder="Search by name..." />
            </Form>
          </Col>
        </Row>

        <MapContainer className="containerMap" center={[-7.708026304958221, 113.22893383627806]} zoom={14} scrollWheelZoom fullscreenControl>
          <BasemapLayer name="ImageryLabels" />
          <BasemapLayer name="Imagery" />
          <ScaleControl />

          <LayersControl position="topright">

            {state?.dataLivePosition.map((item, index) => (
              <div key={item.flight_number}>
                <LayersControl.Overlay checked name="Track Layer">
                  <Polyline
                    pathOptions={{ color: '#39FF14', weight: 3 }}
                    positions={item.routes}
                  />
                </LayersControl.Overlay>
                {(state.isClick && state.dataShowPopup[index]?.showPopup)
                  ? (
                    <Row className="overlay detailInfo m-0 p-0">
                      <Col className="popup-content col-10 bg-light rounded-4 m-0 p-4">
                        <div>
                          <Row className="mb-2">
                            <Col className="col-auto me-auto">
                              <h3 className="text-primary fw-bold">{item.flight_number}</h3>
                            </Col>
                            <Col className="col-auto opacity-75">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                className="bi bi-x-circle-fill pointer"
                                viewBox="0 0 16 16"
                                onClick={() => handleShowPopup(index)}
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                              </svg>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <Image src={Rockblock} fluid />
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col>
                              <h2 className="fw-bold fs-4">{item.drone_name}</h2>
                            </Col>
                          </Row>
                          <Row className="mb-3 fs-6">
                            <p>
                              <Link
                                className="fw-bold"
                                to={`/user/transponder-list/detail-drone/${item.imei}`}
                                style={{ color: '#EA3F0B' }}
                              >
                                {item.transponder_sn}
                              </Link>
                              <span className="m-0 p-0"> - </span>
                              <span className="m-0 p-0">
                                {item.transponder_imei}
                              </span>
                            </p>
                          </Row>
                          <Row className="mb-2 m-0">
                            <Col className="col-4 p-0">
                              <p className="m-0 p-0 fw-bold">{item.properties.origin ? item?.properties.origin : '-'}</p>
                              <p className="m-0 p-0">{item.properties.departure ? item?.properties.departure : '-'}</p>
                            </Col>
                            <Col className="col-4 p-0 d-flex justify-content-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                              </svg>
                            </Col>
                            <Col className="col-4 p-0 text-end">
                              <p className="m-0 p-0 fw-bold">{item?.properties.destination ? item?.properties.destination : '-'}</p>
                              <p className="m-0 p-0">{item?.properties.arrival ? item?.properties.arrival : '-'}</p>
                            </Col>
                          </Row>
                          <Row className="mb-3 p-2 border border-3 rounded-3">
                            <Col className="col-6 m-0 p-0">
                              <p className="m-0">Ground Speed</p>
                              <p className="m-0 fw-bold">
                                {item.ground_speed}
                                {' '}
                                m/s
                              </p>
                            </Col>
                            <Col className="col-6 m-0 p-0 text-end">
                              <p className="m-0">Vertical Speed</p>
                              <p className="m-0 fw-bold">
                                {item.vertical_speed}
                                {' '}
                                m/s
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3 p-2 border border-3 rounded-3">
                            <Row className="m-0 mb-2 p-0">
                              <Col className="col-6 m-0 p-0">
                                <p className="m-0">Longitude</p>
                                <p className="m-0 fw-bold">{item.longitude}</p>
                              </Col>
                              <Col className="col-6 m-0 p-0 text-end">
                                <p className="m-0">Altitude</p>
                                <p className="m-0 fw-bold">
                                  {item.altitude}
                                  {' '}
                                  m
                                </p>
                              </Col>
                            </Row>
                            <Row className="m-0 mb-2 p-0">
                              <Col className="col-6 m-0 p-0">
                                <p className="m-0">Latitude</p>
                                <p className="m-0 fw-bold">{item.latitude}</p>
                              </Col>
                              <Col className="col-6 m-0 p-0 text-end">
                                <p className="m-0">Pilot</p>
                                {item?.pilots.map((pilot) => (
                                  <p key={pilot.name} className="m-0 fw-bold">
                                    {pilot.name}
                                    {' '}
                                    <span>{pilot.phone}</span>
                                  </p>
                                ))}
                              </Col>
                            </Row>
                          </Row>

                          <Row>
                            <Col className="col-auto ms-auto">
                              <Stack direction="horizontal" gap={3}>
                                <div className="rounded-circle text-white p-3 d-flex align-items-center" style={{ backgroundColor: '#C90B1B' }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                  </svg>
                                </div>
                                <div className="rounded-circle text-white p-3 d-flex align-items-center" style={{ backgroundColor: '#0F4187' }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chat-left-fill" viewBox="0 0 16 16">
                                    <path d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                  </svg>
                                </div>
                              </Stack>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  )
                  : null}

                {(state.isClick && state.dataShowPopup[index]?.showAlert)
                  ? item?.geofencing.status.toLowerCase() !== 'safe'
                    && (
                    <Row className="overlay alertBox m-0 text-white justify-content-center">
                      <Col className="col-6 position-relative py-2 rounded rounded-3 text-center" style={{ 'background-color': statusColor(item?.geofencing.status.toLowerCase()) }}>
                        <p className="m-0 p-0 fw-medium fs-5">
                          {item?.geofencing.message}
                        </p>
                        <div className="pointer closeAlert position-absolute opacity-75">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            className="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            onClick={() => handleShowAlert(index)}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                          </svg>
                        </div>
                      </Col>
                    </Row>
                    )
                  : null}

                <MarkerComp data={item} handleShow={handleShow} index={index} status={item?.geofencing.status.toLowerCase()} />
              </div>
            ))}

            <LayersControl.Overlay checked name="Flight Layer KML">
              <LayerGroup>
                <Polygon
                  pathOptions={{ color: 'white' }}
                  positions={state.dataLayerKML}
                />
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>

        </MapContainer>
      </div>

      <div className="chartWrapper mb-5">
        <Row className="mb-1">
          <Col><h2 className="fs-3 fw-bold">Transponder Overview</h2></Col>
        </Row>
        <Row className="mb-2">
          <Col><p className="text-muted">Total flights in a month</p></Col>
        </Row>
        <Row>
          <Bar
            options={optionsChart}
            data={chartData}
            height={80}
          />
        </Row>
      </div>
    </DashboardLayoutUser>
  );
}

export default DashboardUser;
