/* eslint-disable no-underscore-dangle */
import React, { useEffect, useReducer, useState } from 'react';
import 'styles/User/PilotListUser.css';
import {
  Button, Col, Dropdown, Form, Row, Stack, Table, ButtonGroup,
} from 'react-bootstrap';
import DashboardLayoutUser from 'components/layout/DashboardLayoutUser';
import { getCookie, setCookie } from 'utils/SetCookies';
import { INITIAL_STATE, PilotListUserReducer } from 'reducer/User/PilotListUserReducer';
import axios from 'axios';
import LoadingPageUser from 'components/LoadingPageUser';
import PaginationControl from 'components/PaginationControl';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import AddPilotList from 'components/buttonModal/AddPilotList';
import EditPilotVerified from 'components/buttonModal/EditPilotVerified';
import DeleteData from 'components/buttonModal/DeleteData';
import ShowImageFileBtn from 'components/buttonModal/ShowImageFileBtn';
import ShowPDFFileBtn from 'components/buttonModal/ShowPDFFileBtn';
import AlertReact from 'components/alert/AlertReact';

function PilotListUser() {
  const [state, dispatch] = useReducer(PilotListUserReducer, INITIAL_STATE);

  const [pagination, setPagination] = useState({
    current_page: 1, entries: 10, pagination_items: [], multiple: 0,
  });

  const [showAlert, setShowAlert] = useState(false);

  const [alertDetail, setAlertDetail] = useState({
    message: '', variant: '',
  });

  const auth = { token: getCookie('token') };

  function checkStatus(status) {
    if (status.toLowerCase() === 'verified') {
      return (<div className="status-verified text-left px-3 py-2 rounded">Verified</div>);
    }
    return (<div className="status-unverified text-left px-3 py-2 rounded">Unverified</div>);
  }

  const getDataPagination = async () => {
    axios({
      method: 'get',
      url: `pilots/pagination/${pagination.current_page}/${pagination.entries}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        if (res.data.status !== 204) {
          const dataBaru = res.data.data.results;

          dispatch({
            type: 'UPDATE_DATA',
            payload: {
              name: 'dataUser',
              data: dataBaru,
            },
          });
        }
      }
    });
  };

  const fetchGetPilotList = async () => {
    axios({
      method: 'get',
      url: `pilots/pagination/${pagination.current_page}/${pagination.entries}`,
      headers: {
        authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      if (res) {
        if (res.data.status !== 204) {
          const dataBaru = res.data.data.results;

          for (let number = 1; number <= res.data.data.total_pages; number += 1) {
            pagination.pagination_items.push(number);
          }

          dispatch({
            type: 'SET_DATA',
            payload: {
              name: 'dataUser',
              data: dataBaru,
            },
          });
        }
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      }
    });
  };

  useEffect(() => {
    getDataPagination();
  }, [pagination.current_page, pagination.entries]);

  useEffect(() => {
    if (auth.token) {
      dispatch({ type: 'SET_IS_LOADING', payload: true });
      setCookie('navName', 'Pilot List');
      fetchGetPilotList();
    }
  }, []);

  if (state.isLoading) {
    return <LoadingPageUser />;
  }
  return (
    <DashboardLayoutUser>
      <Row className="position-relative">
        <div className="position-fixed end-0 me-4" style={{ width: 'inherit' }}>
          <AlertReact
            message={alertDetail.message}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            variant={alertDetail.variant}
          />
        </div>
      </Row>

      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/user">Dashboard</a></li>
          <li className="breadcrumb-item"><a href="/user/transponder-list">Transponder</a></li>
          <li className="breadcrumb-item active" aria-current="page">Pilot List</li>
        </ol>
      </nav>
      <Row className="mb-3">
        <Col className="col-auto me-auto"><h1 className="fs-2 fw-bold">Pilot List</h1></Col>
        <Col className="col-auto">
          <AddPilotList setAlertDetail={setAlertDetail} setShowAlert={setShowAlert} />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-auto me-auto">
          <p className="fs-5">
            Showing
            {' '}
            {pagination.multiple + 1}
            {' '}
            to
            {' '}
            {(pagination.multiple + parseInt(pagination.entries, 10) < pagination.pagination_items.length) ? (pagination.multiple + parseInt(pagination.entries, 10)) : pagination.pagination_items.length}
            {' '}
            of
            {' '}
            {pagination.entries}
            {' '}
            entries
          </p>
        </Col>
        <Col className="col-auto">
          <Stack direction="horizontal" gap={3}>
            <Dropdown onSelect={(e) => setPagination({
              ...pagination, current_page: 1, entries: e, multiple: 0,
            })}
            >
              <DropdownToggle variant="light" id="entries" className="fw-semibold d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>
                <p className="m-0 p-0">
                  {pagination.entries}
                  {' '}
                  Entries
                </p>
              </DropdownToggle>

              <DropdownMenu>
                <Dropdown.Item eventKey="10">10</Dropdown.Item>
                <Dropdown.Item eventKey="20">20</Dropdown.Item>
                <Dropdown.Item eventKey="30">30</Dropdown.Item>
                <Dropdown.Item eventKey="40">40</Dropdown.Item>
                <Dropdown.Item eventKey="50">50</Dropdown.Item>
              </DropdownMenu>
            </Dropdown>
            <Button type="button" className="btn btn-light fw-semibold d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-list-ul me-2" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
              <p className="m-0 p-0">Column</p>
            </Button>
            <Button type="button" className="btn btn-light fw-semibold d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-download me-2" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
              </svg>
              <p className="m-0 p-0">Export</p>
            </Button>
          </Stack>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-3 ms-auto">
          <Form.Control
            type="text"
            id="searchBar"
            placeholder="Search..."
          />
        </Col>
      </Row>

      <Table hover className="align-middle table-borderless">
        <thead>
          <tr className="text-center">
            <th className="leftCornerTableRadius">No</th>
            <th>Full Name</th>
            <th>NIK</th>
            <th>Phone</th>
            <th>KTP</th>
            <th>Pilot Certificate</th>
            <th>Status</th>
            <th className="rightCornerTableRadius">Action</th>
          </tr>
        </thead>
        <tbody>
          {state.dataUser.length !== 0 ? state?.dataUser?.map((item, index) => (
            <tr key={item._id}>
              <td className="text-left">{pagination.pagination_items[index + (pagination.multiple)]}</td>
              <td className="text-left">{item.name}</td>
              <td className="text-left">{item.nik}</td>
              <td className="text-left">{item.phone}</td>
              <td className="text-left">
                <ShowImageFileBtn
                  name="ktp"
                  image={item.ktp}
                  title="KTP Pilot"
                />
              </td>
              <td className="text-left">
                <ShowPDFFileBtn
                  name="sertifikatPilot"
                  fileURL={item.certificate}
                  title="Pilot Certificate"
                />
              </td>
              <td className="text-center">
                {checkStatus(item.status)}
              </td>
              <td>
                <div className="d-flex justify-content-center">
                  {/* <Button variant="primary" disabled={item.status.toLowerCase() === 'verified'} onClick={() => handleShow(item._id, true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                  </Button> */}
                  <ButtonGroup>
                    <EditPilotVerified />
                    {/* <EditPilotExpired />
                    <EditPilotUnverified /> */}
                    <DeleteData id={item._id} setAlertDetail={setAlertDetail} setShowAlert={setShowAlert} />
                  </ButtonGroup>
                </div>

              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="8" className="text-left">No data found</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Row className="align-items-center justify-content-center">
        <Col className="col-auto">
          <PaginationControl itemsPerPage={pagination.entries} items={pagination.pagination_items} pagination={pagination} setPagination={setPagination} />
        </Col>
      </Row>

    </DashboardLayoutUser>
  );
}

export default PilotListUser;
